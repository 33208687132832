import React, { useEffect, useMemo, useState } from 'react';
import API from '@utils/api';
import orderStore from '@store/order';
import DataTable from '@components/DataTable/Table';
import { prepareParams } from '@utils/common';
import { Button, Text, useDisclosure } from '@chakra-ui/react';
import Order from '@components/StatusLabel/Order';
import moment from 'moment';
import OrderModal from '@components/MyOrders/view';

function Index({ trigger }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, serOrderId] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const viewOrder = (id) => {
    serOrderId(id);
    onOpen();
  };

  const fetchData = (pageSize, pageIndex, sortBy) => {
    setIsLoading(true);

    const params = prepareParams({
      pageSize,
      pageIndex,
      sortBy,
      expand: 'purchase',
    });

    orderStore.setMyPageSize(pageSize);
    orderStore.setMyPageIndex(pageIndex);
    orderStore.setMySortBy(sortBy);

    API.order
      .my(params)
      .then((response) => {
        orderStore.setMyPageCount(
          parseFloat(response.headers['x-pagination-page-count']),
        );
        setData(response.data);
      })
      .finally(() => setIsLoading(false));
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Номер заявки',
        accessor: 'id',
      },
      {
        Header: 'Номер закупки',
        id: 'purchase_id',
        accessor: 'purchase.id',
      },
      {
        Header: 'Объект закупки',
        accessor: 'purchase.name_object',
      },
      {
        Header: 'Моя цена',
        accessor: 'price_finish',
        Cell: ({ value }) => Intl.NumberFormat("ru-RU", {
          style: "currency",
          currency: "RUB",
        }).format(value),
      },
      {
        Header: 'Статус',
        accessor: 'status',
        Cell: ({ value }) => <Order value={value} />,
      },
      {
        Header: 'Дата проведения торгов',
        id: 'purchase.trading_start_date',
        accessor: 'purchase.trading_start_date',
        // Старт торгов после окончания подачи заявок + 2 дня
        Cell: (props) => moment(props.value).add(2, 'd').format('lll'),
      },
      {
        Header: 'Навигация',
        Cell: ({ row }) => (
          <Button
            onClick={() => viewOrder(row.original.id)}
            size="sm"
            variant="outline"
            colorScheme="gray"
          >
            Просмотр
          </Button>
        ),
      },
    ],
    [],
  );

  useEffect(() => orderStore.setPageIndex(0), [trigger]);

  return (
    <>
      {orderId && <OrderModal isOpen={isOpen} orderId={orderId} onClose={onClose} />}
      {trigger === true && (
        <DataTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          pageCount={orderStore.myPageCount}
          pageIndex={orderStore.myPageIndex}
          loading={isLoading}
        />
      )}
    </>
  );
}

export default Index;
