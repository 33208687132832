import React from 'react';
import { Icon } from '@chakra-ui/react';

import {
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineLock,
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineUser,
  AiOutlineDelete,
  AiOutlineUpload,
  AiOutlinePlus,
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineCalendar,
  AiOutlineFile,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineHeart,
  AiFillHeart,
  AiOutlinePrinter,
  AiOutlineDownload,
  AiOutlineFileAdd,
  AiOutlineBarChart,
  AiOutlineLineChart,
  AiOutlineWallet,
} from 'react-icons/ai';
import { RiLogoutCircleRLine } from 'react-icons/ri';
import {
  HiChevronDoubleLeft,
  IoIosAttach,
  IoMdNotificationsOutline,
  HiChevronDoubleRight,
  HiChevronRight,
  HiChevronLeft,
  FaSun,
  FaMoon,
  BiCheck,
  MdOutlineEditNotifications,
} from 'react-icons/all';

export const IconLogout = (props) => <Icon as={RiLogoutCircleRLine} {...props} />;
export const IconUser = (props) => <Icon as={AiOutlineUser} {...props} />;
export const IconLock = (props) => <Icon as={AiOutlineLock} {...props} />;
export const IconEyeOpen = (props) => <Icon as={AiFillEye} {...props} />;
export const IconEyeClose = (props) => <Icon as={AiFillEyeInvisible} {...props} />;
export const IconArrowLeftDouble = (props) => <Icon as={HiChevronDoubleLeft} {...props} />;
export const IconArrowRightDouble = (props) => <Icon as={HiChevronDoubleRight} {...props} />;
export const IconArrowRight = (props) => <Icon as={HiChevronRight} {...props} />;
export const IconArrowLeft = (props) => <Icon as={HiChevronLeft} {...props} />;
export const IconPlus = (props) => <Icon as={AiOutlinePlus} {...props} />;
export const IconNotification = (props) => <Icon as={IoMdNotificationsOutline} {...props} />;
export const IconClose = (props) => <Icon as={AiFillEyeInvisible} {...props} />;
export const IconRemove = (props) => <Icon as={AiOutlineDelete} {...props} />;
export const IconAttach = (props) => <Icon as={IoIosAttach} {...props} />;
export const IconUpload = (props) => <Icon as={AiOutlineUpload} {...props} />;
export const IconCalendar = (props) => <Icon as={AiOutlineCalendar} {...props} />;
export const IconPhone = (props) => <Icon as={AiOutlinePhone} {...props} />;
export const IconMail = (props) => <Icon as={AiOutlineMail} {...props} />;
export const IconArrowUp = (props) => <Icon as={AiOutlineArrowUp} {...props} />;
export const IconArrowDown = (props) => <Icon as={AiOutlineArrowDown} {...props} />;
export const IconArrowLeft2 = (props) => <Icon as={AiOutlineArrowLeft} {...props} />;
export const IconArrowRight2 = (props) => <Icon as={AiOutlineArrowRight} {...props} />;
export const IconFile = (props) => <Icon as={AiOutlineFile} {...props} />;
export const IconMoon = (props) => <Icon as={FaMoon} {...props} />;
export const IconSun = (props) => <Icon as={FaSun} {...props} />;
export const IconFavouriteOutline = (props) => <Icon as={AiOutlineHeart} {...props} />;
export const IconFavouriteFill = (props) => <Icon as={AiFillHeart} {...props} />;
export const IconPrint = (props) => <Icon as={AiOutlinePrinter} {...props} />;
export const IconDownload = (props) => <Icon as={AiOutlineDownload} {...props} />;
export const IconCheck = (props) => <Icon as={BiCheck} {...props} />;
export const IconAddFile = (props) => <Icon as={AiOutlineFileAdd} {...props} />;
export const IconChartBar = (props) => <Icon as={AiOutlineBarChart} {...props} />;
export const IconChartLine = (props) => <Icon as={AiOutlineLineChart} {...props} />;
export const IconNotificationsSettings = (props) => <Icon as={MdOutlineEditNotifications} {...props} />;
export const IconBalance = (props) => <Icon as={AiOutlineWallet} {...props} />;
