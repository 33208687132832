import React, { useEffect } from 'react';
import orderStore from '@store/order';
import { Badge } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import { observer } from 'mobx-react-lite';

function Order({ value }) {
  useEffect(() => {
    if (isEmpty(orderStore.statusList)) {
      orderStore.fetchStatusList();
    }
  }, []);

  return (
    <Badge
      variant="solid"
      colorScheme={
        value === 0
          ? 'red'
          : value === 1
            ? 'blue'
            : value === 2
              ? 'yellow'
              : value === 3
                ? 'green'
                : value === 4
                  ? 'cyan'
                  : 'gray'
      }
    >
      {orderStore.statusList[value] || value}
    </Badge>
  );
}

export default observer(Order);
