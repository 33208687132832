import React from 'react';
import { Alert, AlertIcon, AlertTitle, Flex, useColorModeValue } from '@chakra-ui/react';

function NotFound() {
  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor={useColorModeValue('gray.200', 'gray.600')}
      justifyContent="center"
      alignItems="center"
    >
      <Alert
        status="warning"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="100%"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          404 - Страница не найдена
        </AlertTitle>
      </Alert>
    </Flex>
  );
}

export default NotFound;
