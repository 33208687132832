import React, { useEffect, useMemo, useState } from 'react'
import contractStore from '@store/contract'
import { prepareParams } from '@utils/common'
import API from '@utils/api'
import StatusLabel from '@components/StatusLabel'
import { Button, Flex, useDisclosure } from '@chakra-ui/react'
import DataTable from '@components/DataTable/Table'
import ViewModal from '@components/Contracts/view'

function Index({ trigger }) {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [contractId, setContractId] = useState(false)

  const { onOpen, isOpen, onClose } = useDisclosure()

  const showContractViewHandle = (id) => {
    setContractId(id)
    onOpen()
  }

  const fetchData = (pageSize, pageIndex, sortBy) => {
    setIsLoading(true)

    const params = prepareParams({ pageSize, pageIndex, sortBy, expand: 'user,contractor,purchase,order' })

    contractStore.setPageIndex(pageIndex)
    contractStore.setPageSize(pageSize)
    contractStore.setSortBy(sortBy)

    API.contract
      .my(params)
      .then((response) => {
        contractStore.setPageCount(
          parseFloat(response.headers['x-pagination-page-count']),
        )
        setData(response.data)
      })
      .finally(() => setIsLoading(false))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Номер контракта',
        accessor: 'id',
      },
      {
        Header: 'Номер закупки',
        accessor: 'purchase_id',
      },
      {
        Header: 'Объект закупки',
        accessor: 'purchase.name_object',
      },
      {
        Header: 'Цена',
        accessor: 'order.price_finish',
        Cell: ({ value }) => Intl.NumberFormat('ru-RU', {
          style: 'currency',
          currency: 'RUB',
        }).format(value),
      },
      {
        Header: 'Статус',
        accessor: 'status',
        Cell: ({ value }) => <StatusLabel value={value} />,
      },
      // {
      //   Header: 'Дата окончания подачи заявок',
      //   accessor: 'deadline_submission',
      //   Cell: (props) => moment(props.value).format('lll'),
      // },
      {
        Header: 'Навигация',
        Cell: ({ row }) => (
          <Flex>
            <Button
              size='sm'
              variant='outline'
              colorScheme='gray'
              mr={5}
              onClick={() => showContractViewHandle(row.original.id)}
            >
              Просмотр
            </Button>
          </Flex>
        ),
      },
    ],
    [],
  )

  useEffect(() => contractStore.setPageIndex(0), [trigger])

  return (
    <>
      {contractId && (
        <ViewModal
          isOpen={isOpen}
          onClose={onClose}
          contractId={contractId}
        />
      )}

      {trigger === true && (
        <DataTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          pageCount={contractStore.pageCount}
          pageIndex={contractStore.pageIndex}
          loading={isLoading}
        />
      )}
    </>
  )
}

export default Index
