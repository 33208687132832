import React, { useEffect, useState } from 'react';
import {
  Button,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import API from '@utils/api';
import userStore from '@store/user';
import { observer } from 'mobx-react-lite';
import label from '@utils/labels';
import moment from 'moment';
import { isEmpty } from 'lodash-es';
import { toJS } from 'mobx';
import ProfileChange from '@components/Profile/Change';

const style = {
  td: {
    fontSize: 14,
  },
};

const SkeletonWrapper = ({
  text,
  isLoaded,
  width = '300px',
  height = '15px',
}) => (
  <Skeleton
    isLoaded={!isLoaded}
    w={isLoaded ? width : 'auto'}
    h={isLoaded ? height : 'auto'}
  >
    {text}
  </Skeleton>
);

function Index() {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const formatPhoneNumber = (str) => {
    if (str) {
      const match = str.match(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);

      if (match) {
        return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
      }
    }

    return null;
  };

  const [, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    if (isEmpty(toJS(userStore.profile))) {
      userStore.setProfileIsLoading(true);

      API.user
        .profile()
        .then((response) => {
          if (response.status === 200) {
            userStore.setProfile(response.data);
          }
        })
        .finally(() => userStore.setProfileIsLoading(false));
    }

    // bugfix: Can't perform a React State update on unMounted
    // @see https://stackoverflow.com/questions/60959950/cant-perform-a-react-state-update-on-unmounted-child-component
    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <div>
      <ProfileChange onClose={onClose} isOpen={isOpen} />

      <Button onClick={onOpen} size="sm">Изменить профиль</Button>

      <Table mt={5} size="md">
        <Tbody>
          <Tr>
            <Th width="300px">Название организации</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={userStore.user.username}
              />
            </Td>
          </Tr>

          <Tr>
            <Th width="300px">Email</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={userStore.user.email}
              />
            </Td>
          </Tr>

          <Tr>
            <Th width="300px">Телефон</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={formatPhoneNumber(userStore.user.phone)}
              />
            </Td>
          </Tr>

          <Tr>
            <Th>{label('legal_address')}</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={userStore.profile?.legal_address}
              />
            </Td>
          </Tr>

          <Tr>
            <Th>{label('actual_address')}</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={userStore.profile?.actual_address}
              />
            </Td>
          </Tr>

          <Tr>
            <Th>{label('ogrn')}</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={userStore.profile?.ogrn}
              />
            </Td>
          </Tr>

          <Tr>
            <Th>{label('inn')}</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={userStore.profile?.inn}
              />
            </Td>
          </Tr>

          <Tr>
            <Th>{label('kpp')}</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={userStore.profile?.kpp}
              />
            </Td>
          </Tr>

          <Tr>
            <Th>{label('checking_account')}</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={userStore.profile?.checking_account}
              />
            </Td>
          </Tr>

          <Tr>
            <Th>{label('correspondence_account')}</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={userStore.profile?.correspondence_account}
              />
            </Td>
          </Tr>

          <Tr>
            <Th>{label('bank')}</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={userStore.profile?.bank}
              />
            </Td>
          </Tr>

          <Tr>
            <Th>{label('bank_address')}</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={userStore.profile?.bank_address}
              />
            </Td>
          </Tr>

          <Tr>
            <Th>{label('bikk')}</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={userStore.profile?.bikk}
              />
            </Td>
          </Tr>

          <Tr>
            <Th>{label('head_position')}</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={userStore.profile?.head_position}
              />
            </Td>
          </Tr>

          <Tr>
            <Th>{label('head_name')}</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={userStore.profile?.head_name}
              />
            </Td>
          </Tr>

          <Tr>
            <Th>{label('registration_date')}</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={
                  userStore.profile?.registration_date
                  && moment(userStore.profile?.registration_date)
                    .locale('ru')
                    .format('L')
                }
              />
            </Td>
          </Tr>

          <Tr>
            <Th>{label('contact_person')}</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={userStore.profile?.contact_person}
              />
            </Td>
          </Tr>

          <Tr>
            <Th>{label('additional_phone')}</Th>
            <Td sx={style.td}>
              <SkeletonWrapper
                isLoaded={userStore.user.profileIsLoading}
                text={formatPhoneNumber(userStore.profile?.additional_phone)}
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </div>
  );
}

export default observer(Index);
