import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import API from '@utils/api';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Order from '@components/StatusLabel/Order';
import UpdateModal from '@components/Order/UpdateModal';

const style = {
  td: {
    fontSize: 14,
  },
};

function OrderModal({ orderId, isOpen, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const history = useHistory();

  const {
    isOpen: isOpenUpdate,
    onClose: onCloseUpdate,
    onOpen: onOpenUpdate,
  } = useDisclosure();

  const fetchData = () => {
    setIsLoading(true);

    API.order
      .view(orderId, { expand: 'purchase' })
      .then((response) => {
        setData(response.data);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    if (orderId && isOpen === true) {
      fetchData();
    }
  }, [orderId, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Заявка №{orderId}</ModalHeader>
        <ModalBody pb={5}>
          {isLoading && <Progress size="xs" isIndeterminate />}
          {!isLoading && (
            <>
              <Table mt={5} size="md">
                <Tbody>
                  <Tr>
                    <Th width="240px">Закупка № {data.purchase_id}</Th>
                    <Td sx={style.td}>
                      <Button
                        size="sm"
                        variant="outline"
                        colorScheme="gray"
                        onClick={() =>
                          history.push(`/trade/${data.purchase_id}?prev=2`)
                        }
                      >
                        Просмотр
                      </Button>
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Статус</Th>
                    <Td>
                      <Order value={data.status} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Дата подачи</Th>
                    <Td sx={style.td}>
                      {moment.unix(data.created_at).locale('ru').format('llll')}
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Стартовая цена</Th>
                    <Td sx={style.td}>
                      {Intl.NumberFormat("ru-RU", {
                        style: "currency",
                        currency: "RUB",
                      }).format(data.price_start)}
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Финальная цена</Th>
                    <Td sx={style.td}>
                      {Intl.NumberFormat("ru-RU", {
                        style: "currency",
                        currency: "RUB",
                      }).format(data.price_finish)}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>

              <UpdateModal
                isOpen={isOpenUpdate}
                onClose={onCloseUpdate}
                orderData={data}
                fetchData={fetchData}
              />

              {(data.status === 1 || data.status === 4) && (
                <Flex>
                  <Button mt={5} ml="auto" onClick={onOpenUpdate}>
                    Изменить заявку
                  </Button>
                </Flex>
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default OrderModal;
