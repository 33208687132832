import { makeAutoObservable } from 'mobx';

/**
 * Common app store
 */
class AppStore {
  isLoading = false;

  centrifugo = null;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(value) {
    this.isLoading = value;
  }

  setCentrifugo(value) {
    this.centrifugo = value;
  }
}

export default new AppStore();
