import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import notificationsStore from '@store/notifications';
import userStore from '@store/user';
import appStore from '@store/app';
import { observer } from 'mobx-react-lite';
import API from '@utils/api';
import jwtDecode from 'jwt-decode';
import NotificationModal from '@components/Notifications/NotificationModal';
import moment from 'moment';
import { IconNotification, IconRemove } from '@utils/icons';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash-es';

const styles = {
  item: {
    borderBottom: '1px solid #eee',
    padding: '16px 5px',
  },
};

function Index() {
  const [messageId, setMessageId] = useState('');

  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: onOpenModal,
    isOpen: isOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const fetchNotifications = () => {
    API.notifications.list().then((response) => {
      notificationsStore.setNotifications(response.data);
    });
  };

  const removeNotification = (id) => {
    API.notifications
      .remove(id)
      .then(() => {
        fetchNotifications();
      })
      .catch((error) => {
        toast.error(error?.response?.data || 'Ошибка удаления уведомления');
      });
  };

  const viewNotification = (id) => {
    onOpenModal();
    setMessageId(id);
  };

  useEffect(() => {
    fetchNotifications();

    // Common channel
    appStore.centrifugo.subscribe('common', (message) => {
      if (message?.data) {
        fetchNotifications();
      }
    });

    const decodedToken = jwtDecode(userStore.token);
    const userId = decodedToken?.sub || null;

    // Private
    if (userId !== null) {
      appStore.centrifugo.subscribe(`user#${userId}`, (message) => {
        if (message?.data) {
          fetchNotifications();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchNotifications();
      /**
       * После открытия, через 3 секунды помечаем все сообщения как прочитанные
       * и сбрасываем счетчик новых сообщений
       */
      if (notificationsStore.newCounter > 0) {
        setTimeout(() => {
          API.notifications.readAll().then(() => {
            notificationsStore.setNewCounter(0);
          });
        }, 3000);
      }
    }
  }, [isOpen]);

  return (
    <>
      {messageId && (
        <NotificationModal
          isOpen={isOpenModal}
          onClose={onCloseModal}
          messageID={messageId}
          fetchNotifications={fetchNotifications}
        />
      )}

      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <PopoverTrigger>
          <Box position="relative">
            <IconButton
              icon={<IconNotification w={7} h={7} color="whiteAlpha.900" />}
              variant="ghost"
              aria-label="show"
              sx={{
                _hover: {
                  icon: {
                    color: '#000',
                  },
                },
              }}
            />

            {notificationsStore.newCounter > 0 && (
              <Badge
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
                colorScheme="green"
                variant="solid"
              >
                {notificationsStore.newCounter}
              </Badge>
            )}
          </Box>
        </PopoverTrigger>
        <PopoverContent
          sx={{
            _focus: {
              shadow: 'none',
            },
          }}
        >
          <PopoverArrow />
          <PopoverCloseButton top={2} />
          <PopoverHeader>Уведомления</PopoverHeader>

          <PopoverBody
            overflowY="scroll"
            maxH="300px"
            css={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <>
              {isEmpty(notificationsStore.notifications) && (
                <Text
                  fontSize={12}
                  color={useColorModeValue('gray.400', 'gray.500')}
                >
                  Уведомлений нет
                </Text>
              )}
              {notificationsStore.notifications.map((item) => (
                <Box
                  sx={styles.item}
                  key={item.id}
                  // TODO: сменить цвета под текущую dark-light схему
                  bgColor={item.status === 'new' ? 'green.50' : ''}
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text fontSize={14} isTruncated>
                      {/* Убираем html тэги в превью, если есть */}
                      {JSON.parse(item.data).message.replace(/<[^>]+>/g, '')}
                    </Text>

                    <IconButton
                      ml={2}
                      aria-label="Удалить"
                      icon={<IconRemove w={4} h={4} />}
                      variant="ghost"
                      colorScheme="red"
                      size="sm"
                      onClick={() => removeNotification(item.id)}
                    />
                  </Flex>

                  <Flex justifyContent="space-between" alignItems="center">
                    <Box
                      fontSize={13}
                      color="gray.500"
                      _hover={{ color: '#000' }}
                      cursor="pointer"
                      onClick={() => viewNotification(item.id)}
                    >
                      Подробнее
                    </Box>

                    <Box fontSize={12} color="gray.500">
                      {moment
                        .unix(item.created_at)
                        .format('DD.MM.YYYY H:mm:ss')}
                    </Box>
                  </Flex>
                </Box>
              ))}
            </>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}

export default observer(Index);
