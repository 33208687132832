import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Progress,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet-async';
import API from '@utils/api';
import Version from '@components/Version';
import useDynamicRefs from 'use-dynamic-refs';
import {
  IconAttach,
  IconEyeClose,
  IconEyeOpen,
  IconLock,
  IconMail,
  IconPhone,
  IconRemove,
} from '@utils/icons';
import { toast } from 'react-toastify';

const Success = ({ email }) => (
  <Box
    maxW="600px"
    backgroundColor="whiteAlpha.900"
    boxShadow="md"
    mt={3}
    p={5}
    px={10}
  >
    <Text>
      На адрес
      {' '}
      <b>{email}</b>
      {' '}
      отправлено письмо для подтверждения регистрации
    </Text>
  </Box>
);

function Signup() {
  const [getRef, setRef] = useDynamicRefs();

  const [showPassword, setShowPassword] = useState(false);

  const [success, setSuccess] = useState(false);

  const handleShowClick = () => {
    setShowPassword(!showPassword);
  };

  const [isDocTypesLoading, setIsDocTypesLoading] = useState(false);
  const [docTypes, setDocTypes] = useState({});

  useEffect(() => {
    setIsDocTypesLoading(true);
    API.user.getDocumentTypes().then((response) => {
      if (response.status === 200) {
        const array = {};
        Object.entries(response.data).forEach(([key, value]) => {
          array[key] = value;
        });
        setDocTypes(array);
      }
    }).finally(() => setIsDocTypesLoading(false));
  }, []);

  const FILE_SIZE = 1024 * 1024 * 20; // 20 МБ

  const [yupShape, setYupShape] = useState({
    username: yup
      .string()
      .max(256, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    phone: yup
      .string()
      .max(16, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    legal_address: yup
      .string()
      .max(512, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    actual_address: yup
      .string()
      .max(512, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    ogrn: yup
      .string()
      .max(16, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    inn: yup
      .string()
      .max(16, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    kpp: yup
      .string()
      .max(16, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    checking_account: yup
      .string()
      .max(64, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    correspondence_account: yup
      .string()
      .max(64, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    bank: yup
      .string()
      .max(128, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    bank_address: yup
      .string()
      .max(512, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    bik: yup
      .string()
      .max(16, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    head_position: yup
      .string()
      .max(64, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    head_name: yup
      .string()
      .max(64, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    registration_date: yup.string().required('Обязательное поле'),
    contact_person: yup.string().max(64, 'Превышена максимальная длина поля'),
    additional_phone: yup.string().max(16, 'Превышена максимальная длина поля'),

    email: yup
      .string()
      .email('Неправильный формат email')
      .required('Обязательное поле'),

    password: yup
      .string()
      .min(6, 'Минимальная длина 6 символов')
      .required('Обязательное поле'),
    passwordRepeat: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Пароли не совпадают'),
  });

  const validationSchema = yup.lazy(() => yup.object().shape(yupShape));

  const [formikInitialValues, setFormikInitialValues] = useState({
    username: '',
    email: '',
    password: '',
    passwordRepeat: '',
    phone: '',
    legal_address: '',
    actual_address: '',
    ogrn: '',
    inn: '',
    kpp: '',
    checking_account: '',
    correspondence_account: '',
    bank: '',
    bank_address: '',
    bik: '',
    head_position: '',
    head_name: '',
    registration_date: '',
    contact_person: '',
    additional_phone: '',
  });

  useEffect(() => {
    if (docTypes) {
      const yArray = { ...yupShape };
      const array = { ...formikInitialValues };
      // eslint-disable-next-line array-callback-return
      Object.entries(docTypes).map(([key]) => {
        array[key] = '';

        if (getRef(key).current !== null) {
          yArray[key] = yup
            .mixed()
            .test(
              'fileSize',
              'Размер файла не должен превышать 20МБ',
              () => !getRef(key).current.files[0]
                || (getRef(key).current.files[0]
                  && getRef(key).current.files[0].size <= FILE_SIZE),
            );
        }
      });

      setYupShape({ ...yupShape, ...yArray });
      setFormikInitialValues({ ...formikInitialValues, ...array });
    }
  }, [docTypes]);

  const handleFileClick = (k) => {
    getRef(k).current.click();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { setErrors }) => {
      const data = new FormData();

      const reCaptcha = await window.grecaptcha.execute(process.env.REACT_APP_KEY_V3, { action: 'homepage' });

      if (!reCaptcha) {
        toast.warning('Капча не готова. Перезагрузите страницу.');
        return;
      }

      data.append('reCaptcha', reCaptcha);

      Object.entries(values).forEach(([key, value]) => {
        let v = value;
        // Убираем все, кроме цифр
        if (key === 'phone' || key === 'additional_phone') {
          v = value.replace(/[^\d]/g, '');
        }

        data.append(key, v);
      });

      await API.user
        .signup(data)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            toast.success('Успешно!');
            setSuccess(true);
          } else {
            toast.error('Ошибка!');
            // eslint-disable-next-line no-console
            console.log('error', response);
          }
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            // Ошибка валидации

            const description = [];
            if (error.response?.data?.message) {
              const message = JSON.parse(error.response.data.message);
              setErrors(message);

              // eslint-disable-next-line
              for (const [_, value] of Object.entries(message)) {
                description.push(`- ${value}`);
              }
              const html = `Ошибка!\n${description.join('\n')}`;
              return toast.error(html);
            }

            toast.error('Ошибка!');
          } else if (error.response?.status === 500) {
            // Ошибка сервера

            if (error.response?.data?.message) {
              toast.error(error.response.data.message);
            }
          } else toast.error('Неизвестная ошибка. Повторите позже');
        });
    },
  });

  const handleFileChange = (k) => {
    formik.setFieldValue(k, getRef(k).current.files[0]);
  };

  if (success === true) {
    return (
      <Flex
        flexDirection="column"
        width="100wh"
        minH="100vh"
        backgroundColor="gray.200"
        justifyContent="center"
        alignItems="center"
      >
        <Heading color="brand.blueBlack" mt={5}>
          Успешно
        </Heading>
        <Success email={formik.values.email} />
      </Flex>
    );
  }

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      minH="100vh"
      bg={useColorModeValue('gray.200', 'gray.600')}
      // backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Helmet>
        <title>Регистрация – Stom Tender</title>
      </Helmet>

      <Heading color={useColorModeValue('brand.blueBlack', 'gray.400')} mt={5}>
        Регистрация
      </Heading>
      <form onSubmit={formik.handleSubmit}>
        <Box
          maxW="1200px"
          bg={useColorModeValue('whiteAlpha.900', 'gray.800')}
          // backgroundColor="whiteAlpha.900"
          boxShadow="md"
          mt={3}
          p={5}
          py={10}
          px={10}
        >
          <FormControl isRequired isInvalid={formik.errors.username} mb={5}>
            <FormLabel>Название организации</FormLabel>
            <Input
              type="text"
              maxW="50%"
              {...formik.getFieldProps('username')}
            />
            <FormErrorMessage>{formik.errors.username}</FormErrorMessage>
          </FormControl>

          <Grid templateColumns="repeat(4, 1fr)" gap={5} mb={5}>
            <FormControl isRequired isInvalid={formik.errors.email}>
              <FormLabel>Email</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<IconMail color="gray.300" />}
                />
                <Input type="text" {...formik.getFieldProps('email')} />
              </InputGroup>
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={formik.errors.phone}>
              <FormLabel>Мобильный</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<IconPhone color="gray.300" />}
                />
                <Input
                  type="text"
                  as={InputMask}
                  mask="+7(999)999-99-99"
                  {...formik.getFieldProps('phone')}
                />
              </InputGroup>
              <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={formik.errors.password}>
              <FormLabel>Пароль</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  children={<IconLock color="gray.300" />}
                />
                <Input
                  type={showPassword ? 'text' : 'password'}
                  {...formik.getFieldProps('password')}
                />
                <InputRightElement>
                  <IconButton
                    variant="ghost"
                    size="sm"
                    onClick={handleShowClick}
                    icon={
                      showPassword ? <IconEyeClose /> : <IconEyeOpen />
                    }
                    aria-label="Показать пароль"
                    tabIndex={-1}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={formik.errors.passwordRepeat}>
              <FormLabel>Повторить пароль</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  children={<IconLock color="gray.300" />}
                />
                <Input
                  type={showPassword ? 'text' : 'password'}
                  {...formik.getFieldProps('passwordRepeat')}
                />
                <InputRightElement>
                  <IconButton
                    variant="ghost"
                    size="sm"
                    onClick={handleShowClick}
                    icon={
                      showPassword ? <IconEyeClose /> : <IconEyeOpen />
                    }
                    aria-label="Показать пароль"
                    tabIndex={-1}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {formik.errors.passwordRepeat}
              </FormErrorMessage>
            </FormControl>
          </Grid>

          <Divider mb={6} mt={5} />

          <FormControl
            isRequired
            mb={5}
            isInvalid={formik.errors.legal_address}
          >
            <FormLabel>Юридический адрес</FormLabel>
            <Input type="text" {...formik.getFieldProps('legal_address')} />
            <FormErrorMessage>{formik.errors.legal_address}</FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            mb={5}
            isInvalid={formik.errors.actual_address}
          >
            <FormLabel>Фактический адрес</FormLabel>
            <Input type="text" {...formik.getFieldProps('actual_address')} />
            <FormErrorMessage>{formik.errors.actual_address}</FormErrorMessage>
          </FormControl>

          <Grid templateColumns="1fr 1fr" gap={5} mb={5}>
            <FormControl isRequired isInvalid={formik.errors.bik}>
              <FormLabel>БИК</FormLabel>
              <Input type="text" {...formik.getFieldProps('bik')} />
              <FormErrorMessage>{formik.errors.bik}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={formik.errors.bank}>
              <FormLabel>Банк</FormLabel>
              <Input type="text" {...formik.getFieldProps('bank')} />
              <FormErrorMessage>{formik.errors.bank}</FormErrorMessage>
            </FormControl>
          </Grid>

          <FormControl isRequired isInvalid={formik.errors.bank_address} mb={5}>
            <FormLabel>Адрес банка</FormLabel>
            <Input type="text" {...formik.getFieldProps('bank_address')} />
            <FormErrorMessage>{formik.errors.bank_address}</FormErrorMessage>
          </FormControl>

          <Grid templateColumns="repeat(3, 1fr)" gap={5} mb={5}>
            <FormControl isRequired isInvalid={formik.errors.ogrn}>
              <FormLabel>ОГРН</FormLabel>
              <Input type="text" {...formik.getFieldProps('ogrn')} />
              <FormErrorMessage>{formik.errors.ogrn}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={formik.errors.inn}>
              <FormLabel>ИНН</FormLabel>
              <Input type="text" {...formik.getFieldProps('inn')} />
              <FormErrorMessage>{formik.errors.inn}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={formik.errors.kpp}>
              <FormLabel>КПП</FormLabel>
              <Input type="text" {...formik.getFieldProps('kpp')} />
              <FormErrorMessage>{formik.errors.kpp}</FormErrorMessage>
            </FormControl>
          </Grid>

          <Grid templateColumns="repeat(2, 1fr)" gap={5} mb={5}>
            <FormControl isRequired isInvalid={formik.errors.checking_account}>
              <FormLabel>Расчетный счет</FormLabel>
              <Input
                type="text"
                {...formik.getFieldProps('checking_account')}
              />
              <FormErrorMessage>
                {formik.errors.checking_account}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isRequired
              isInvalid={formik.errors.correspondence_account}
            >
              <FormLabel>Кор. счет</FormLabel>
              <Input
                type="text"
                {...formik.getFieldProps('correspondence_account')}
              />
              <FormErrorMessage>
                {formik.errors.correspondence_account}
              </FormErrorMessage>
            </FormControl>
          </Grid>

          <Grid templateColumns="repeat(3, 1fr)" gap={5} mb={5}>
            <FormControl isRequired isInvalid={formik.errors.head_position}>
              <FormLabel>Должность руководителя</FormLabel>
              <Input type="text" {...formik.getFieldProps('head_position')} />
              <FormErrorMessage>{formik.errors.head_position}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={formik.errors.head_name}>
              <FormLabel>ФИО руководителя</FormLabel>
              <Input type="text" {...formik.getFieldProps('head_name')} />
              <FormErrorMessage>{formik.errors.head_name}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={formik.errors.registration_date}>
              <FormLabel>Дата регистрации организации</FormLabel>
              <Input
                type="text"
                as={InputMask}
                mask="99.99.9999"
                {...formik.getFieldProps('registration_date')}
              />
              <FormErrorMessage>
                {formik.errors.registration_date}
              </FormErrorMessage>
            </FormControl>
          </Grid>

          <Grid templateColumns="repeat(3, 1fr)" gap={5} mb={5}>
            <FormControl isInvalid={formik.errors.contact_person}>
              <FormLabel>Контактное лицо</FormLabel>
              <Input type="text" {...formik.getFieldProps('contact_person')} />
              <FormErrorMessage>
                {formik.errors.contact_person}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formik.errors.additional_phone}>
              <FormLabel>Доп. телефон</FormLabel>
              <Input
                type="text"
                as={InputMask}
                mask="+7(999)999-99-99"
                {...formik.getFieldProps('additional_phone')}
              />
              <FormErrorMessage>
                {formik.errors.additional_phone}
              </FormErrorMessage>
            </FormControl>
          </Grid>

          <Flex justifyContent="space-between" flexWrap="wrap">
            {isDocTypesLoading && <Progress size="xs" isIndeterminate />}

            {docTypes && Object.entries(docTypes).map(([key, value]) => (
              <Box w="30%" key={key}>
                <FormControl isInvalid={formik.errors[key]}>
                  <Flex alignItems="center">
                    <IconButton
                      aria-label="file"
                      icon={<IconAttach />}
                      variant="outline"
                      onClick={() => handleFileClick(key)}
                      mr={2}
                      size="lg"
                    />
                    <FormLabel>
                      {value}
                      {' '}
                      (.doc, .pdf, .zip, .rar, .xls)
                    </FormLabel>
                  </Flex>

                  <Input
                    onClick={(e) => (e.target.value = null)}
                    ref={setRef(key)}
                    onChange={() => handleFileChange(key)}
                    type="file"
                    name={key}
                    sx={{ display: 'none' }}
                  />

                  <FormErrorMessage>{formik.errors[key]}</FormErrorMessage>

                  {formik.getFieldProps(key).value && (
                    <Flex alignItems="center" mt={2}>
                      <Text fontSize={13}>{formik.getFieldProps(key).value.name}</Text>
                      <IconButton
                        icon={<IconRemove />}
                        variant="outline"
                        size="xs"
                        ml={2}
                        onClick={() => {
                          formik.resetForm({ values: { ...formik.values, [key]: '' } });
                          getRef(key).current.value = null;
                        }}
                        aria-label="remove"
                      />
                    </Flex>
                  )}
                </FormControl>
              </Box>
            ))}
          </Flex>
        </Box>

        <Box textAlign="center" maxW={600} m="auto" mt={3}>
          <Text fontSize={13}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Нажимая кнопку "Зарегистрироваться", Вы даете согласие на обработку
            своих персональных данных и принимаете условия Пользовательского
            соглашения.
          </Text>

          <Button
            mt={5}
            type="submit"
            isLoading={formik.isSubmitting}
          >
            Зарегистрироваться
          </Button>

          <Box mt={8} mb="100px">
            <Link
              as={RouterLink}
              to="/login"
              color={useColorModeValue('brand.blueDark', 'gray.400')}
              fontWeight={600}
            >
              Уже зарегистрирован
            </Link>
          </Box>
          <Box mt={5} mb={5}>
            <Version />
          </Box>
        </Box>
      </form>
    </Flex>
  );
}

export default Signup;
