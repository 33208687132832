import React, { useEffect, useMemo, useState } from 'react';
import API from '@utils/api';
import purchaseStore from '@store/purchase';
import moment from 'moment';
import {
  Button,
  Flex,
  IconButton,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { IconFavouriteFill, IconPrint } from '@utils/icons';
import { toast } from 'react-toastify';
import { ErrorParser, prepareParams } from '@utils/common';
import DataTable from '@components/DataTable/Table';
import { observer } from 'mobx-react-lite';
import StatusLabel from '@components/StatusLabel';
import { useHistory } from 'react-router-dom';
import ViewModal from '@components/Purchases/View';
import appStore from '@store/app';
import { isEmpty } from 'lodash-es';

function Favourites({ trigger }) {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseId, setPurchaseId] = useState(false);
  const [addFavouriteIsLoading, setAddFavouriteIsLoading] = useState(false);

  const { onOpen, isOpen, onClose } = useDisclosure();

  const viewPurchase = (id) => {
    setPurchaseId(id);
    onOpen();
  };

  const fetchData = (pageSize, pageIndex, sortBy) => {
    setIsLoading(true);

    const params = prepareParams({ pageSize, pageIndex, sortBy, expand: 'user' });

    purchaseStore.setFavouritePageIndex(pageIndex);
    purchaseStore.setFavouritePageSize(pageSize);
    purchaseStore.setFavouriteSortBy(sortBy);

    API.purchase
      .favourites(params)
      .then((response) => {
        purchaseStore.setFavouritePageCount(
          parseFloat(response.headers['x-pagination-page-count']),
        );
        setData(response.data);
      })
      .finally(() => setIsLoading(false));
  };

  const removeFavourite = (id) => {
    setAddFavouriteIsLoading(true);
    API.purchase
      .removeFavourite(id)
      .then((response) => {
        if (response.data === 'true') {
          toast.warning('Удалено из избранного');
        } else {
          toast.error('Не удалось удалить из избранного');
        }
      })
      .catch((error) => ErrorParser(error))
      .finally(() => {
        setAddFavouriteIsLoading(false);
        fetchData(
          purchaseStore.favouritePageSize,
          purchaseStore.favouritePageIndex,
          purchaseStore.favouriteSortBy,
        );
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Номер',
        accessor: 'id',
      },
      {
        Header: 'Заказчик',
        id: 'user_id',
        accessor: 'user.username',
      },
      {
        Header: 'Объект закупки',
        accessor: 'name_object',
      },
      {
        Header: 'Цена',
        accessor: 'approximate_price',
        Cell: ({ value }) => Intl.NumberFormat("ru-RU", {
          style: "currency",
          currency: "RUB",
        }).format(value),
      },
      {
        Header: 'Статус',
        accessor: 'status',
        Cell: ({ value }) => <StatusLabel value={value} />,
      },
      {
        Header: 'Дата окончания подачи заявок',
        accessor: 'deadline_submission',
        Cell: (props) => moment(props.value).format('lll'),
      },
      {
        Header: 'Навигация',
        Cell: ({ row }) => (
          <Flex>
            <Button
              onClick={() => {
                if (row.original.status === 3 || row.original.status === 4) {
                  history.push(`/trade/${row.original.id}`);
                } else {
                  viewPurchase(row.original.id);
                }
              }}
              size="sm"
              variant="outline"
              colorScheme="gray"
              mr={5}
            >
              Просмотр
            </Button>
            <Tooltip label="Из избранного">
              <IconButton
                aria-label="favourite"
                icon={<IconFavouriteFill />}
                size="sm"
                variant="outline"
                colorScheme="red"
                ml={5}
                onClick={() => removeFavourite(row.original.id)}
                isLoading={addFavouriteIsLoading}
              />
            </Tooltip>
            <Tooltip label="Распечатать">
              <IconButton
                aria-label="print"
                icon={<IconPrint />}
                size="sm"
                variant="outline"
                colorScheme="gray"
                ml={1}
              />
            </Tooltip>
          </Flex>
        ),
      },
    ],
    [purchaseStore.statusList],
  );

  useEffect(() => purchaseStore.setFavouritePageIndex(0), [trigger]);

  useEffect(() => {
    // Update data
    appStore.centrifugo.subscribe('common_service', (message) => {
      if (message?.data === 'update_purchases') {
        fetchData(
          purchaseStore.pageSize,
          purchaseStore.pageIndex,
          purchaseStore.sortBy,
        );
      }
    });

    if (isEmpty(purchaseStore.statusList)) {
      purchaseStore.fetchStatusList();
    }
  }, []);

  return (
    <>
      {purchaseId && (
        <ViewModal
          isOpen={isOpen}
          onClose={onClose}
          purchaseId={purchaseId}
          showButtons
        />
      )}

      {trigger === true && (
        <DataTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          pageCount={purchaseStore.favouritePageCount}
          pageIndex={purchaseStore.favouritePageIndex}
          loading={isLoading}
        />
      )}
    </>
  );
}

export default observer(Favourites);
