import { makeAutoObservable } from 'mobx';

class ContractStore {
  pageSize = 0;

  pageIndex = 0;

  pageCount = 0;

  sortBy = {};

  constructor() {
    makeAutoObservable(this);
  }

  setPageSize(value) {
    this.pageSize = value;
  }

  setPageIndex(value) {
    this.pageIndex = value;
  }

  setPageCount(value) {
    this.pageCount = value;
  }

  setSortBy(value) {
    this.sortBy = value;
  }

  setStatusListIsLoading(value) {
    this.statusListIsLoading = value;
  }
}

export default new ContractStore();
