import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Text,
} from '@chakra-ui/react';
import API from '@utils/api';
import * as yup from 'yup';
import { useFormik } from 'formik';
import userStore from '@store/user';
import { toast } from 'react-toastify';

function Index({ isOpen, onClose }) {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Не верный формат email')
      .required('Обязательное поле'),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      email:
        (settings?.settings && JSON.parse(settings?.settings).email)
        || userStore.user.email,
      interval: (settings?.settings && JSON.parse(settings?.settings).interval)
        || 60 * 24,
      status: settings?.status ?? true,
    },
    enableReinitialize: true,
    onSubmit: async (values, { setErrors }) => {
      const data = new FormData();

      data.append('user_id', userStore.user.id);
      data.append('status', Number(values.status));
      data.append('settings', JSON.stringify({ email: values.email, interval: values.interval }));

      await API.notificationsSettings.update(data)
        .then((response) => {
          if (response.status === 200 && response.data === true) {
            toast.success('Настройки обновлены');
            onClose();
          }
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            if (error.response?.data?.message) {
              const message = JSON.parse(error.response.data.message);
              setErrors(message);
            }
          } else {
            toast.error('Ошибка сервера. Повторите позже');
          }
        });
    },
  });

  const fetchSettings = () => {
    setLoading(true);
    API.notificationsSettings
      .get()
      .then((response) => {
        setSettings(response.data);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (isOpen) {
      fetchSettings();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>Настройки уведомлений</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading && (
              <Flex justifyContent="center" pt={5}>
                <CircularProgress isIndeterminate />
              </Flex>
            )}

            {!loading && (
              <Box>
                <Text mb={10}>
                  Настройка уведомление о начале торгов на Email
                </Text>
                <FormControl display="flex" alignItems="center" mb={5}>
                  <FormLabel htmlFor="status-check" mb="0">
                    Присылать уведомления?
                  </FormLabel>
                  <Switch
                    id="status-check"
                    defaultChecked={formik.initialValues.status}
                    onChange={(e) => {
                      console.log(e.target.checked);
                      formik.setFieldValue('status', e.target.checked);
                    }}
                  />
                </FormControl>

                <FormControl mb={5}>
                  <FormLabel>Интервал уведомлений</FormLabel>
                  <Select {...formik.getFieldProps('interval')}>
                    <option value={60 * 24}>За день</option>
                    <option value={60 * 2}>За 2 часа</option>
                    <option value={60}>За 1 час</option>
                    <option value={10}>За 10 минут</option>
                  </Select>
                </FormControl>

                <FormControl isInvalid={formik.errors.email}>
                  <FormLabel>Email для уведомлений</FormLabel>
                  <Input type="email" {...formik.getFieldProps('email')} />
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="submit" mr={3} isLoading={formik.isSubmitting}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Отмена
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default Index;
