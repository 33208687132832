import { makeAutoObservable, reaction } from 'mobx';
import API from '@utils/api';
import appStore from '@store/app';

export const APP_TOKEN = 'APP_TOKEN';

class UserStore {
  isLoading = false;

  profileIsLoading = false;

  error = null;

  user = {};

  profile = {};

  token = localStorage.getItem(APP_TOKEN) || null;

  reactionSetLocalstorage = (variable, key) => (variable
    ? localStorage.setItem(key, variable)
    : localStorage.removeItem(key));

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.token,
      (token) => {
        this.reactionSetLocalstorage(token, APP_TOKEN);
      },
    );
  }

  getUser() {
    this.setIsLoading(true);
    this.setError(null);

    API.user
      .me()
      .then((response) => {
        if (response?.status === 200) {
          this.setUser(response.data);
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          this.logout();
        } else {
          this.setError(`Server error: ${error.response?.data?.message}`);
        }
      })
      .finally(() => this.setIsLoading(false));
  }

  getProfile() {
    this.setError(null);

    API.user
      .profile()
      .then((response) => {
        if (response.status === 200) {
          this.setProfile(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.logout();
        } else {
          this.setError(`Server error: ${error.response.data.message}`);
        }
      });
  }

  setProfile(value) {
    this.profile = value;
  }

  setProfileIsLoading(value) {
    this.profileIsLoading = value;
  }

  setUser(user) {
    this.user = user;
  }

  setToken(token) {
    this.token = token;
  }

  setIsLoading(value) {
    this.isLoading = value;
  }

  setError(value) {
    this.error = value;
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    localStorage.removeItem(APP_TOKEN);
    window.location.href = '/login';
    appStore.centrifugo.disconnect();
  }
}

export default new UserStore();
