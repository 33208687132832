import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import API from '@utils/api';
import {
  IconArrowLeft2, IconDownload,
  IconFavouriteFill,
  IconFavouriteOutline,
} from '@utils/icons';
import moment from 'moment';
import { ErrorParser } from '@utils/common';
import OrderModal from '@components/Order/CreateModal';
import { toast } from 'react-toastify';
import StatusLabel from '@components/StatusLabel';

function View({ purchaseId, isOpen, onClose, showButtons }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [isFavLoading, setIsFavLoading] = useState(false);
  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const {
    isOpen: isOpenOrderModal,
    onOpen: onOpenOrderModal,
    onClose: onCloseOrderModal,
  } = useDisclosure();

  const onCloseHandler = () => {
    setData({});
    onClose();
  };

  const getFile = (fileName) => {
    setIsFileDownloading(true);
    API.purchase
      .getFile(fileName)
      .then((response) => {
        if (response.status === 200) {
          try {
            const a = document.createElement('a');
            const blob = new Blob([response.data], {
              type: response.headers['content-type'],
            });
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
          } catch (e) {
            toast.error(`Ошибка! ${e}`);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          return toast.error('Ошибка! У вас нет доступа');
        }
        if (error.response.status === 404) {
          return toast.error('Ошибка! Файл не найден');
        }
        return toast.error(`Ошибка ${error.response.status}. Повторите позже`);
      })
      .finally(() => setIsFileDownloading(false));
  };

  const fetchPurchase = () => {
    setIsLoading(true);

    API.purchase
      .view(purchaseId, { expand: 'is_favourite,status_label,is_ordered' })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403) onCloseHandler();

        ErrorParser(error);
      })
      .finally(() => setIsLoading(false));
  };

  const favouriteHandle = () => {
    setIsFavLoading(true);
    if (data.is_favourite) {
      API.purchase
        .removeFavourite(purchaseId)
        .then(() => fetchPurchase())
        .catch((error) => ErrorParser(error))
        .finally(() => setIsFavLoading(false));
    } else {
      API.purchase
        .addFavourite(purchaseId)
        .then(() => fetchPurchase())
        .catch((error) => ErrorParser(error))
        .finally(() => setIsFavLoading(false));
    }
  };

  useEffect(() => {
    if (purchaseId && isOpen === true) {
      fetchPurchase();
    }
  }, [purchaseId, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onCloseHandler} size="full">
      <ModalOverlay />
      <ModalContent borderRadius={0}>
        <ModalBody pt={10}>
          <Box maxW="1024px" m="auto">
            <Flex mb={5}>
              <IconButton
                aria-label="close"
                mr={10}
                icon={<IconArrowLeft2 />}
                size="lg"
                variant="outline"
                onClick={onCloseHandler}
              />

              <Flex>
                <Heading mb={5}>
                  Закупка №
                  {' '}
                  {data.id}
                </Heading>
              </Flex>
            </Flex>

            {isLoading && <Progress size="xs" isIndeterminate />}

            {!isLoading && (
              <>
                <Alert status="info" mb={10}>
                  Наименование объекта закупки:
                  {' '}
                  {data.name_object}
                </Alert>

                <OrderModal
                  isOpen={isOpenOrderModal}
                  onClose={onCloseOrderModal}
                  purchaseId={purchaseId}
                  fetchPurchase={fetchPurchase}
                  purchasePrice={data.approximate_price}
                />

                {showButtons === true && (
                  <HStack>
                    <Button
                      onClick={onOpenOrderModal}
                      isDisabled={data.status !== 1 || data.is_ordered === true}
                    >
                      {data.is_ordered === true ? 'Заявка подана' : 'Подать заявку'}
                    </Button>
                    <Button variant="outline">Запросить документы</Button>
                    <Button
                      onClick={favouriteHandle}
                      isLoading={isFavLoading}
                      leftIcon={
                        data.is_favourite ? (
                          <IconFavouriteFill />
                        ) : (
                          <IconFavouriteOutline />
                        )
                      }
                      variant="outline"
                    >
                      {data.is_favourite
                        ? 'Удалить из избранного'
                        : 'Добавить в избранное'}
                    </Button>
                  </HStack>
                )}

                <Table mt={5} size="sm" variant="unstyled">
                  <Tbody>
                    <Tr>
                      <Th>Статус закупки</Th>
                      <Td><StatusLabel value={data.status} /></Td>
                    </Tr>
                    <Tr>
                      <Th>Дата и время начала подачи заявок</Th>
                      <Td>{moment.unix(data.created_at).format('llll')}</Td>
                    </Tr>
                    <Tr>
                      <Th>Дата и время окончания подачи заявок</Th>
                      <Td>{moment(data.deadline_submission).format('llll')}</Td>
                    </Tr>
                    <Tr>
                      <Th>Дата и время начала торгов</Th>
                      <Td>
                        {moment(data.trading_start_date).format('llll')}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>Дата и время окончания торгов</Th>
                      <Td>
                        {data.trade_time_end && moment.unix(data.trade_time_end).format('llll')}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>Только оригинал/Можно предложить эквивалент</Th>
                      <Td>
                        {data.exact_match === 1 && 'Только оригинал'}
                        {data.exact_match === 0
                          && 'Можно предложить эквивалент'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>Приблизительная цена</Th>
                      <Td>
                        {Intl.NumberFormat("ru-RU", {
                          style: "currency",
                          currency: "RUB",
                        }).format(data.approximate_price)}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>Описание</Th>
                      <Td>{data.description}</Td>
                    </Tr>
                    <Tr>
                      <Th>Объект закупки</Th>
                      <Td>
                        {data.files && JSON.parse(data.files).map((fileName) => (
                          <Button
                            isLoading={isFileDownloading}
                            key={fileName}
                            onClick={() => getFile(fileName)}
                            variant="outline"
                            size="sm"
                            colorScheme="gray"
                            leftIcon={<IconDownload />}
                          >
                            {fileName}
                          </Button>
                        ))}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>

                <Text color="gray.400" fontSize={12} mt={10}>
                  Комиссия площадки за выигрыш в аукционе 2%
                </Text>
              </>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default View;
