import { makeAutoObservable, reaction } from 'mobx';
import { filter } from 'lodash-es';

class NotificationsStore {
  notifications = [];

  newCounter = 0;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.notifications,
      (notifications) => {
        const newCount = filter(notifications, { status: 'new' }).length;
        this.setNewCounter(newCount);
      },
    );
  }

  setNewCounter(value) {
    this.newCounter = value;
  }

  setNotifications(value) {
    this.notifications = value;
  }
}

export default new NotificationsStore();
