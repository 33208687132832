import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Icon,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';
import userStore from '@store/user';
import { VscCircleFilled } from 'react-icons/all';
import { observer } from 'mobx-react-lite';
import CreatePurchase from '@components/Purchases/Create';
import MyPurchases from '@components/Purchases/My';
import Purchases from '@components/Purchases';
import Contracts from '@components/Contracts';
import Favourites from '@components/Purchases/Favourites';
import MyOrders from '@components/MyOrders';
import { IconPlus } from '@utils/icons';

function Main() {
  // Параметр для открытия нужной вкладки из URL
  const prevParam = new URLSearchParams(window.location.search).get('prev');

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [tabTrigger, setTabTrigger] = useState(null);

  const changeTabHandler = (e) => {
    // 0 - Реестр закупок
    // 1 - Мои закупки
    // 2 - Мои заявки
    // 3 - Мои контракты
    // 4 - Избранное
    setTabTrigger(e);
  };

  useEffect(() => {
    if (prevParam) {
      const tab = parseFloat(prevParam);
      if (tab >= 0 && tab < 7) {
        setTabTrigger(tab);
      } else {
        // Если индекс вкладки за пределами общего количества, ставим открытой первую вкладку
        setTabTrigger(0);
      }
      // Очищаем параметры в URL
      window.history.replaceState(null, null, window.location.pathname);
    }
  }, [prevParam]);

  useEffect(() => setTabTrigger(0), []);

  return (
    <Box maxW={{ tablet: '1200px', fullHd: '1600px' }} m="auto" px={2}>
      <Helmet>
        <title>Личный кабинет – Stom Tender</title>
      </Helmet>

      <Text fontSize="xl" mt={5} mb={5} fontWeight="bold">
        Личный кабинет
      </Text>

      <Button
        rightIcon={<IconPlus />}
        size="lg"
        mt={3}
        bgColor="brand.red"
        color="whiteAlpha.900"
        onClick={onOpen}
        isDisabled={userStore.user?.accreditation_status !== 1}
      >
        Создать закупку
      </Button>

      <CreatePurchase
        onClose={onClose}
        isOpen={isOpen}
        trigger={setTabTrigger}
      />

      <Table mt={5} size="sm" variant="unstyled">
        <Tbody>
          <Tr>
            <Th w="240px">Состояние аккредитации</Th>
            <Td>
              {userStore.user?.accreditation_status === 2 && (
                <Flex alignItems="center">
                  <Icon as={VscCircleFilled} color="yellow.400" mr={2} />
                  Ожидает подтверждения
                </Flex>
              )}
              {userStore.user?.accreditation_status === 1 && (
                <Flex alignItems="center">
                  <Icon as={VscCircleFilled} color="green.500" mr={2} />
                  Активна
                </Flex>
              )}
              {userStore.user?.accreditation_status === 0 && (
                <Flex alignItems="center">
                  <Icon as={VscCircleFilled} color="red.500" mr={2} />
                  Не аккредитован
                </Flex>
              )}
            </Td>
          </Tr>
        </Tbody>
      </Table>

      <Box
        p={3}
        bg={useColorModeValue('whiteAlpha.900', 'gray.800')}
        boxShadow="sm"
        mt={10}
      >
        <Tabs onChange={changeTabHandler} index={tabTrigger || 0}>
          <TabList>
            <Tab _focus={{ outline: 'none' }}>Реестр закупок</Tab>
            <Tab _focus={{ outline: 'none' }}>Мои закупки</Tab>
            <Tab _focus={{ outline: 'none' }}>Мои заявки</Tab>
            <Tab _focus={{ outline: 'none' }}>Мои контракты</Tab>
            <Tab _focus={{ outline: 'none' }}>Избранное</Tab>
          </TabList>

          <TabPanels>
            <TabPanel overflowY="auto">
              <Purchases trigger={tabTrigger === 0} />
            </TabPanel>
            <TabPanel overflowY="auto">
              <MyPurchases trigger={tabTrigger === 1} />
            </TabPanel>
            <TabPanel overflowY="auto">
              <MyOrders trigger={tabTrigger === 2} />
            </TabPanel>
            <TabPanel overflowY="auto">
              <Contracts trigger={tabTrigger === 3} />
            </TabPanel>
            <TabPanel overflowY="auto">
              <Favourites trigger={tabTrigger === 4} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}

export default observer(Main);
