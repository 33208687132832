import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Text } from '@chakra-ui/react';

const calculateDuration = (eventTime) => moment.duration(Math.max(eventTime - Math.floor(Date.now() / 1000), 0), 'seconds');

function Countdown({ eventTime }) {
  const [duration, setDuration] = useState(calculateDuration(eventTime));
  const timerRef = useRef(0);
  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime));
  }, [eventTime]);

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [eventTime]);

  return (
    <Text fontWeight="semibold">
      {duration.minutes()}
      :
      {(`0${duration.seconds()}`).slice(-2)}
    </Text>
  );
}

export default Countdown;
