import React, { useCallback, useEffect, useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
} from '@chakra-ui/react'
import { ErrorParser } from '@utils/common'
import API from '@utils/api'
import { isEmpty } from 'lodash-es'
import { IconDownload, IconFile, IconRemove, IconUpload } from '@utils/icons'
import { toast } from 'react-toastify'
import userStore from '@store/user'
import { useFormik } from 'formik'
import { useDropzone } from 'react-dropzone'
import AttachSecurityFile from '@components/Contracts/AttachSecurityFile'

function View({ isOpen, onClose, contractId }) {
  const [contract, setContract] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isFileDownloading, setIsFileDownloading] = useState(false)
  const [isDocumentDownloading, setIsDocumentDownloading] = useState(null)
  const [userType, setUserType] = useState('')
  const [showSignButton, setShowSignButton] = useState(false)

  const fetchData = () => {
    setIsLoading(true)
    API.contract
      .view(contractId, { expand: 'user,contractor,purchase,order' })
      .then((response) => setContract(response.data))
      .catch((error) => ErrorParser(error))
      .finally(() => setIsLoading(false))
  }

  const getFile = (fileName) => {
    setIsFileDownloading(true)
    API.purchase
      .getFile(fileName)
      .then((response) => {
        if (response.status === 200) {
          try {
            const a = document.createElement('a')
            const blob = new Blob([response.data], {
              type: response.headers['content-type'],
            })
            const url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = fileName
            a.click()
            window.URL.revokeObjectURL(url)
          } catch (e) {
            toast.error(`Ошибка! ${e}`)
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          return toast.error('Ошибка! У вас нет доступа')
        }
        if (error.response.status === 404) {
          return toast.error('Ошибка! Файл не найден')
        }
        return toast.error(`Ошибка ${error.response.status}. Повторите позже`)
      })
      .finally(() => setIsFileDownloading(false))
  }

  const getDocument = (fileName, user_type) => {
    setIsDocumentDownloading(fileName)
    API.contract
      .getDocument(fileName, contractId, user_type)
      .then((response) => {
        if (response.status === 200) {
          try {
            const a = document.createElement('a')
            const blob = new Blob([response.data], {
              type: response.headers['content-type'],
            })
            const url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = fileName
            a.click()
            window.URL.revokeObjectURL(url)
          } catch (e) {
            toast.error(`Ошибка! ${e}`)
          }
        }
      })
      .catch((error) => {
        if (error.response?.status === 422) {
          return toast.error('Ошибка! У вас нет доступа')
        }
        if (error.response?.status === 404) {
          return toast.error('Ошибка! Файл не найден')
        }
        return toast.error(`Ошибка ${error.response?.status}. Повторите позже`)
      })
      .finally(() => setIsDocumentDownloading(null))
  }

  const formik = useFormik({
    initialValues: {
      files: [],
    },
    onSubmit: async (values) => {
      const formData = new FormData()
      values.files.map((file) => formData.append('files[]', file))

      formData.append('contract_id', contractId)
      formData.append('user_type', userType)

      await API.contract.uploadDocument(formData)
        .then((response) => {
          if (response?.data === 'OK') {
            formik.resetForm()
            toast.success('Документы загружены')
            setShowSignButton(true)
          }
        })
        .catch((error) => ErrorParser(error))
    },
  })

  const onDrop = useCallback(acceptedFiles => {
    formik.setFieldValue('files', formik.values.files.concat(acceptedFiles))
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  useEffect(() => {
    if (isOpen === true) {
      fetchData()
    }
    if (isOpen === false) {
      setShowSignButton(false);
    }
  }, [isOpen])

  useEffect(() => {
    if (!isEmpty(contract)) {
      if (userStore.user.id === contract.contractor_id) {
        setUserType('contractor')
      }

      if (userStore.user.id === contract.user_id) {
        setUserType('user')
      }
    }
  }, [contract])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Контракт № {contractId}
          {!isEmpty(contract) &&
            <Badge ml={2} colorScheme={contract?.status === 'Не подписан' ? 'red' : 'green'}>{contract.status}</Badge>}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={10}>

          {isLoading && <Progress size='xs' isIndeterminate />}

          {!isLoading && !isEmpty(contract) && (
            <>
              <Table>
                <Tbody>
                  <Tr>
                    <Td colSpan={2}>
                      <Text fontWeight={600}>Сведения о закупке</Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Наименование объекта закупки</Th>
                    <Td>{contract.purchase.name_object}</Td>
                  </Tr>
                  <Tr>
                    <Th>Объект закупки</Th>
                    <Td>
                      {contract.purchase.files && JSON.parse(contract.purchase.files).map((fileName) => (
                        <Button
                          isLoading={isFileDownloading}
                          key={fileName}
                          onClick={() => getFile(fileName)}
                          variant='outline'
                          size='sm'
                          colorScheme='gray'
                          leftIcon={<IconDownload />}
                        >
                          {fileName}
                        </Button>
                      ))}
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Описание</Th>
                    <Td>{contract.purchase.description}</Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={2}>
                      <Text fontWeight={600} mt={5}>Сведения о контракте</Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Сумма контракта</Th>
                    <Td>
                      {Intl.NumberFormat('ru-RU', {
                        style: 'currency',
                        currency: 'RUB',
                      }).format(contract.order.price_finish)}
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Начальная цена контракта</Th>
                    <Td>
                      {Intl.NumberFormat('ru-RU', {
                        style: 'currency',
                        currency: 'RUB',
                      }).format(contract.purchase.approximate_price)}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={2}>
                      <Text fontWeight={600} mt={5}>Документы</Text>
                    </Td>
                  </Tr>
                  {userType === 'contractor' && contract.status !== 'Подписан' && (
                    <Tr>
                      <Th>{!contract.contract_security_document && 'Загрузите'} платежное поручение</Th>
                      <Td>
                        {contract.contract_security_document && (
                          <Button
                            isLoading={isFileDownloading}
                            key={contract.contract_security_document}
                            onClick={() => getDocument(contract.contract_security_document, 'contractor')}
                            variant='outline'
                            size='sm'
                            colorScheme='gray'
                            mb={5}
                            leftIcon={<IconDownload />}
                          >
                            {contract.contract_security_document}
                          </Button>
                        )}
                        <AttachSecurityFile contractId={contractId} userType={userType} callback={fetchData} />
                      </Td>
                    </Tr>
                  )}
                  <Tr>
                    <Th>На подписание</Th>
                    <Td>
                      {userStore.user.id === contract.contractor_id &&
                        !isEmpty(JSON.parse(contract.documents)?.contractor) &&
                        JSON.parse(contract.documents)?.contractor.map((file) =>
                          <Button
                            isLoading={isDocumentDownloading === file}
                            key={file}
                            onClick={() => getDocument(file, userType)}
                            variant='outline'
                            size='sm'
                            colorScheme='gray'
                            leftIcon={<IconDownload />}
                          >
                            {file}
                          </Button>)}

                      {userStore.user.id === contract.user_id &&
                        !isEmpty(JSON.parse(contract.documents)?.user) &&
                        JSON.parse(contract.documents)?.user.map((file) =>
                          <Button
                            isLoading={isDocumentDownloading === file}
                            key={file}
                            onClick={() => getDocument(file, 'user')}
                            variant='outline'
                            size='sm'
                            colorScheme='gray'
                            leftIcon={<IconDownload />}
                          >
                            {file}
                          </Button>)}
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Подписанные документы</Th>
                    <Td>
                      {userStore.user.id === contract.contractor_id &&
                        !isEmpty(JSON.parse(contract.documents_signed)?.contractor) &&
                        JSON.parse(contract.documents_signed)?.contractor.map((file) =>
                          <Button
                            isLoading={isDocumentDownloading === file}
                            key={file}
                            onClick={() => getDocument(file, 'contractor')}
                            variant='outline'
                            size='sm'
                            colorScheme='gray'
                            leftIcon={<IconDownload />}
                          >
                            {file}
                          </Button>)}

                      {userStore.user.id === contract.user_id &&
                        !isEmpty(JSON.parse(contract.documents_signed)?.user) &&
                        JSON.parse(contract.documents_signed)?.user.map((file) =>
                          <Button
                            isLoading={isDocumentDownloading === file}
                            key={file}
                            onClick={() => getDocument(file, 'user')}
                            variant='outline'
                            size='sm'
                            colorScheme='gray'
                            leftIcon={<IconDownload />}
                          >
                            {file}
                          </Button>)}
                    </Td>
                  </Tr>
                  {contract.status !== 'Подписан' && (
                    <Tr>
                      <Th>Загрузите подписанные документы</Th>
                      <Td>
                        <form onSubmit={formik.handleSubmit}>
                          <Box
                            p={5}
                            bgColor={'gray.50'}
                            borderRadius={'xl'}
                            border='2px'
                            borderColor='gray.300'
                            borderStyle='dashed'
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            {formik.values.files.length === 0 &&
                              <Text fontSize={12}>Перетащите сюда файлы или нажмите для выбора</Text>}
                            {formik.values.files.length > 0 && formik.values.files.map((file) =>
                              <Flex key={file?.name} mb={2} alignItems={'center'}>
                                <IconFile mr={2} />
                                <Text fontSize={12}>{file?.name}</Text>
                                <IconButton
                                  icon={<IconRemove />}
                                  ml={'auto'}
                                  size={'sm'}
                                  variant={'outline'}
                                  aria-label={'remove'}
                                />
                              </Flex>,
                            )}
                          </Box>
                          {!isEmpty(formik.values.files) && (
                            <Button
                              type='submit'
                              size='sm'
                              mt={2}
                              isLoading={formik.isSubmitting}
                              leftIcon={<IconUpload />}
                            >
                              Загрузить
                            </Button>
                          )}
                        </form>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </>
          )}

        </ModalBody>

        {showSignButton && (
          <ModalFooter>
            <Button onClick={onClose}>Подписать</Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}

export default View
