import React from 'react';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import Header from '@components/Header';
import Version from '@components/Version';

function Index({ children }) {
  return (
    <>
      <Box bg={useColorModeValue('gray.200', 'gray.600')} minH="100vh" pb={5}>
        <Header />
        <main>{children}</main>
      </Box>

      <Flex
        bg={useColorModeValue('gray.200', 'gray.600')}
        p={3}
        mt="auto"
        alignItems="center"
        justifyContent="center"
      >
        <Version />
      </Flex>
    </>
  );
}

export default Index;
