import { makeAutoObservable } from 'mobx';
import API from '@utils/api';
import { toast } from 'react-toastify';

class OrderStore {
  pageSize = 0;

  pageIndex = 0;

  pageCount = 0;

  sortBy = {};

  statusList = {};

  statusListIsLoading = false;

  // my orders -----------------------------------------------------------------
  myPageSize = 0;

  myPageIndex = 0;

  myPageCount = 0;

  mySortBy = {};

  constructor() {
    makeAutoObservable(this);
  }

  setPageSize(value) {
    this.pageSize = value;
  }

  setPageIndex(value) {
    this.pageIndex = value;
  }

  setPageCount(value) {
    this.pageCount = value;
  }

  setSortBy(value) {
    this.sortBy = value;
  }

  // my orders -----------------------------------------------------------------
  setMyPageSize(value) {
    this.myPageSize = value;
  }

  setMyPageIndex(value) {
    this.myPageIndex = value;
  }

  setMyPageCount(value) {
    this.myPageCount = value;
  }

  setMySortBy(value) {
    this.mySortBy = value;
  }

  setStatusListIsLoading(value) {
    this.statusListIsLoading = value;
  }

  setStatusList(value) {
    this.statusList = value;
  }

  fetchStatusList() {
    if (this.statusListIsLoading === false) {
      this.setStatusListIsLoading(true);

      API.order
        .status()
        .then((response) => {
          this.setStatusList(response.data);
        })
        .catch(() => {
          toast.error('Не удалось получить список статусов заявок');
        })
        .finally(() => this.setStatusListIsLoading(false));
    }
  }
}

export default new OrderStore();
