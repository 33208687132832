import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { isArray, isEmpty } from 'lodash-es';

/**
 * @link https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 */
function humanFileSize(bytes: number, si = false, dp = 1): string {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    // eslint-disable-next-line no-plusplus
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh
    && u < units.length - 1
  );

  return `${bytes.toFixed(dp)} ${units[u]}`;
}

function isJson(item: string | null): boolean {
  item = typeof item !== 'string' ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  return typeof item === 'object' && item !== null;
}

type FilterParamsOperator = 'AND' | 'OR' | 'NOT' | '<' | '>' | '<=' | '>=' | '=' | '!=' | 'IN' | 'NOT IN' | 'LIKE';

type FilterParams = {
  field: string,
  operator: FilterParamsOperator,
  value: string|number
}

function queryParamsFilter(params: Array<FilterParams>) {
  let string = '';
  params.forEach((item) => {
    string += `filter[${item.field}][${item.operator}]=${item.value}&`;
  });

  // remove last &
  return string.slice(0, -1);
}

function ErrorParser(error: AxiosError) {
  // Ошибка валидации
  if (error.response?.status === 422) {
    const description: string[] = [];

    if (isArray(error.response?.data)) {
      error.response.data.forEach((item) => {
        description.push(`- ${item.message}`);
      });

      const html = `Ошибка!\n${description.join('\n')}`;

      return toast.error(html);
    }

    if (error.response?.data?.message) {
      const message = JSON.parse(error.response.data.message);

      // eslint-disable-next-line
      for (const [_, value] of Object.entries(message)) {
        description.push(`- ${value}`);
      }
      const html = `Ошибка!\n${description.join('\n')}`;

      return toast.error(html);
    }
    return toast.error('Ошибка!');
  }

  // Ошибка сервера
  if (error.response?.status === 500) {
    if (error.response?.data?.message) {
      return toast.error(error.response.data.message);
    }
  }

  // Прочее с ошибкой
  if (error.response?.data?.message) {
    return toast.error(error.response.data.message);
  }

  return toast.error('Неизвестная ошибка. Повторите позже');
}

type SortBy = {
  id: number,
  desc: boolean,
}

type ParamsData = {
  pageIndex?: string|number,
  pageSize?: string|number,
  sortBy?: Array<SortBy>,
  expand?: string,
  status?: string,
}

type Params = {
  page?: string|number,
  'per-page'?: string|number,
  sort?: string,
  expand?: string,
}

function prepareParams(data: ParamsData) {
  const params: Params = {};

  if (data.pageIndex) {
    params.page = data.pageIndex;
  }

  if (data.pageSize) {
    params['per-page'] = data.pageSize;
  }

  if (!isEmpty(data.sortBy)) {
    // @ts-ignore
    let field = String(data.sortBy[0].id);
    // @ts-ignore
    if (data.sortBy[0].desc === true) {
      field = `-${field}`;
    }
    params.sort = field;
  }

  params.expand = data.expand;

  return params;
}

export { humanFileSize, isJson, queryParamsFilter, ErrorParser, prepareParams };
