import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box, Flex, IconButton, Text, useColorMode } from '@chakra-ui/react';
import { IconChartBar, IconChartLine } from '@utils/icons';
import { isEmpty } from 'lodash-es';

function Chart({ orders }) {
  const [chartType, setChartType] = useState('line');
  const [chartData, setChartData] = useState([]);
  const highchartsRef = useRef();

  const { colorMode } = useColorMode();

  const prepareChartData = () => {
    const chartArray = [];
    orders.forEach((order) => {
      if (!isEmpty(order.price_steps)) {
        const stepsArray = [];
        order.price_steps.forEach((step, index) => {
          stepsArray.push({
            // x: step.date * 1000,
            x: index,
            y: parseFloat(step.price),
          });
        });

        chartArray.push({
          name: `Участник ${order.user_id}`,
          data: stepsArray,
        });
      }
    });

    setChartData(chartArray);
  };

  useEffect(() => {
    if (!isEmpty(orders)) {
      prepareChartData();
    }
  }, [orders]);

  const options = {
    title: false,
    credits: {
      enabled: false,
    },
    chart: {
      type: chartType,
      backgroundColor: null,
      // height: '200px',
      style: {
        fontFamily: 'Inter',
      },
      zoomType: 'x',
    },
    series: chartData,
    time: {
      timezoneOffset: (new Date().getTimezoneOffset()),
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          color: colorMode === 'light' ? '#000' : '#fff',
        },
      },
    },
    xAxis: {
      visible: false,
      // type: 'datetime',
      labels: {
        // rotation: 90,
        // format: '{value:%H:%M}',
        style: {
          color: colorMode === 'light' ? '#000' : '#fff',
        },
      },
    },
    legend: {
      itemStyle: {
        color: colorMode === 'light' ? '#000' : '#fff',
      },
    },
    tooltip: {
      formatter() {
        // return `${moment(new Date(this.x)).locale('ru').format('lll')}<br/><b>${this.series.name}: ${this.y} руб.</b>`;
        // eslint-disable-next-line react/no-this-in-sfc
        return `${this.series.name}<br/> <b>${this.y}</b> руб.`;
      },
    },
  };

  return (
    <Box>
      {!isEmpty(chartData) && (
        <>
          <Flex mb={5}>
            <Text fontWeight="semibold">История цены</Text>
            {chartType === 'line' && (
              <IconButton
                aria-label="chart"
                icon={<IconChartBar />}
                ml="auto"
                variant="outline"
                size="sm"
                onClick={() => setChartType('column')}
              />
            )}
            {chartType === 'column' && (
              <IconButton
                aria-label="chart"
                icon={<IconChartLine />}
                ml="auto"
                variant="outline"
                size="sm"
                onClick={() => setChartType('line')}
              />
            )}
          </Flex>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={highchartsRef}
          />
        </>
      )}
    </Box>
  );
}

export default Chart;
