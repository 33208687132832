import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from '@utils/api';
import { ErrorParser, humanFileSize } from '@utils/common';
import { toast } from 'react-toastify';
import { IconAttach, IconCheck, IconFile } from '@utils/icons';
import { AiOutlineDelete } from 'react-icons/ai';
import { isEmpty } from 'lodash-es';

const RadioBtnSecurity = ({ label, value, formik }) => (
  <Button
    variant={formik.values.order_security === value ? 'solid' : 'outline'}
    onClick={() => formik.setFieldValue('order_security', value)}
    leftIcon={formik.values.order_security === value ? <IconCheck /> : null}
  >
    {label}
  </Button>
);

function UpdateModal({ isOpen, onClose, orderData, fetchData = null }) {
  const fileOfferRef = useRef(null);
  const [selectedOfferFile, setSelectedOfferFile] = useState(null);

  const fileCertificateRef = useRef(null);
  const [selectedCertificateFile, setSelectedCertificateFile] = useState(null);

  const securityFileRef = useRef(null);

  const FILE_SIZE = 1024 * 1024 * 20; // 20 МБ

  const validationSchema = yup.object().shape({
    price_min: yup.number()
      .min(1, 'Минимальное значение должно быть больше 1')
      .max(parseFloat(orderData.price_start) + 0.1, 'Ваша минимальная цена больше цены закупки')
      .when('is_auto', {
        is: true,
        then: yup.number().required('Обязательное поле'),
      }),
    is_auto: yup.boolean(),
    fileOffer: yup
      .mixed()
      .test(
        'fileRequired',
        'Файл обязателен',
        () => orderData.file_offer || !selectedOfferFile,
      )
      .test(
        'fileSize',
        'Размер файла не должен превышать 20МБ',
        () => !selectedOfferFile
          || (selectedOfferFile && selectedOfferFile.size <= FILE_SIZE),
      ),
    fileCertificate: yup
      .mixed()
      .test(
        'fileRequired',
        'Файл обязателен',
        () => orderData.file_certificate || !selectedCertificateFile,
      )
      .test(
        'fileSize',
        'Размер файла не должен превышать 20МБ',
        () => !selectedCertificateFile
          || (selectedCertificateFile
            && selectedCertificateFile.size <= FILE_SIZE),
      ),
    security_file: yup
      .mixed()
      .test(
        'securityFileRequired',
        'Прикрепите файл',
        () => {
          if (formik.values.order_security === 'balance') {
            return true;
          }
          return orderData.order_security_document || !isEmpty(securityFileRef.current.files);
        },
      )
      .test('securityFileSize', 'Размер файла не должен превышать 20МБ', () => {
        if (
          orderData.order_security_document
          || orderData.order_security === 'balance'
          || formik.values.order_security === 'balance'
        ) {
          return true;
        }

        if (!isEmpty(securityFileRef.current.files)) {
          return securityFileRef.current.files[0].size < FILE_SIZE;
        }
      }),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      price_min: orderData.price_min || 1,
      is_auto: Boolean(orderData.is_auto) || false,
      step: orderData.step,
      purchase_id: orderData.purchase_id,
      order_security: orderData.order_security || '',
      security_file: orderData.order_security_document || null,
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { resetForm, setErrors }) => {
      const data = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        if (key === 'is_auto') value = Number(value);
        data.append(key, value);
      });

      data.append('fileOffer', selectedOfferFile);
      data.append('fileCertificate', selectedCertificateFile);

      await API.order
        .update(orderData.id, data)
        .then((response) => {
          if (response.status === 200 || response.data === true) {
            toast.success('Заявка успешно обновлена');
            if (fetchData) {
              fetchData();
            }
            resetForm();
            onClose();
          } else {
            ErrorParser(response.data);
          }
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            // Ошибка валидации

            const description = [];
            if (error.response?.data?.message) {
              const message = JSON.parse(error.response.data.message);
              setErrors(message);

              // eslint-disable-next-line
              for (const [_, value] of Object.entries(message)) {
                description.push(`- ${value}`);
              }
              const html = `Ошибка!\n${description.join('\n')}`;
              return toast.error(html);
            }
          }
          ErrorParser(error);
        });
    },
  });

  const closeHandle = () => {
    formik.resetForm();
    setSelectedCertificateFile(null);
    setSelectedOfferFile(null);
    onClose();
  };

  const handleOfferFileChange = (e) => {
    setSelectedOfferFile(e.target.files[0]);
  };

  const clickOfferHandle = () => {
    fileOfferRef.current.click();
  };

  const removeOfferSelected = () => {
    setSelectedOfferFile(null);
  };

  const handleCertificateFileChange = (e) => {
    setSelectedCertificateFile(e.target.files[0]);
  };

  const clickCertificateHandle = () => {
    fileCertificateRef.current.click();
  };

  const removeCertificateSelected = () => {
    setSelectedCertificateFile(null);
  };

  const handleSecurityFileClick = () => {
    securityFileRef.current.click();
  };

  const handleSecurityFileChange = (e) => {
    formik.setFieldValue('security_file', e.target.files[0]);
  };

  return (
    <Modal isOpen={isOpen} onClose={closeHandle}>
      <ModalOverlay />
      <form onSubmit={formik.handleSubmit}>
        <ModalContent borderRadius={0}>
          <ModalHeader>
            Изменить заявку №
            {orderData.id}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pt={10}>
            <Box display="none">
              <FormControl isRequired mb={formik.values.order_security === 'document' ? 5 : 10}>
                <FormLabel mb={0}>Обеспечение закупки</FormLabel>
                <Text color="gray.500" mb={3}>2% от цены закупки</Text>

                <Stack direction="row">
                  <RadioBtnSecurity formik={formik} value="balance" label="Списать со счета" />
                  <RadioBtnSecurity formik={formik} value="document" label="Платежное поручение" />
                </Stack>
              </FormControl>
            </Box>

            {formik.values.order_security === 'document' && (
              <FormControl isInvalid={formik.errors.security_file} mb={10}>
                <Flex alignItems="center">
                  <IconButton
                    aria-label="file"
                    icon={<IconAttach />}
                    variant="outline"
                    onClick={handleSecurityFileClick}
                    mr={2}
                    size="lg"
                  />
                  <FormLabel>
                    Прикрепить платежное поручение (.doc, .pdf, .zip, .rar, .xls)
                  </FormLabel>
                </Flex>

                <Input
                  /* eslint-disable-next-line no-return-assign */
                  onClick={(e) => (e.target.value = null)}
                  ref={securityFileRef}
                  onChange={(e) => handleSecurityFileChange(e)}
                  type="file"
                  name="security_file"
                  sx={{ display: 'none' }}
                />

                {formik.values.security_file !== null && formik.values.security_file.name && (
                  <Flex alignItems="center" mt={2}>
                    <IconFile />
                    <Text fontSize={13} ml={1}>
                      {formik.values.security_file.name}
                      {' '}
                      (
                      {humanFileSize(formik.values.security_file.size)}
                      )
                    </Text>
                  </Flex>
                )}

                <FormErrorMessage>{formik.errors.security_file}</FormErrorMessage>
              </FormControl>
            )}

            <FormControl isInvalid={formik.errors.fileOffer} mb={5}>
              <Flex alignItems="center">
                <IconButton
                  aria-label="file"
                  icon={<IconAttach />}
                  variant="outline"
                  onClick={clickOfferHandle}
                  mr={2}
                  size="lg"
                />
                <FormLabel>
                  Предложение (.doc, .pdf, .zip, .rar, .xls)
                </FormLabel>
              </Flex>

              <FormErrorMessage>{formik.errors.fileOffer}</FormErrorMessage>

              <Input
                ref={fileOfferRef}
                onClick={(e) => (e.target.value = null)}
                onChange={handleOfferFileChange}
                type="file"
                name="fileOffer"
                id="fileOffer"
                sx={{ display: 'none' }}
              />

              {selectedOfferFile && (
                <Flex alignItems="center" mt={2}>
                  <Text fontSize={13}>{selectedOfferFile?.name}</Text>
                  <IconButton
                    icon={<AiOutlineDelete />}
                    variant="outline"
                    size="xs"
                    ml={2}
                    onClick={removeOfferSelected}
                    aria-label="remove"
                  />
                </Flex>
              )}
            </FormControl>

            <FormControl isInvalid={formik.errors.fileCertificate} mb={5}>
              <Flex alignItems="center">
                <IconButton
                  aria-label="file"
                  icon={<IconAttach />}
                  variant="outline"
                  onClick={clickCertificateHandle}
                  mr={2}
                  size="lg"
                />
                <FormLabel>Сертификат (.doc, .pdf, .zip, .rar, .xls)</FormLabel>
              </Flex>

              <FormErrorMessage>
                {formik.errors.fileCertificate}
              </FormErrorMessage>

              <Input
                ref={fileCertificateRef}
                onClick={(e) => (e.target.value = null)}
                onChange={handleCertificateFileChange}
                type="file"
                id="fileCertificate"
                sx={{ display: 'none' }}
              />

              {selectedCertificateFile && (
                <Flex alignItems="center" mt={2}>
                  <Text fontSize={13}>{selectedCertificateFile?.name}</Text>
                  <IconButton
                    icon={<AiOutlineDelete />}
                    variant="outline"
                    size="xs"
                    ml={2}
                    onClick={removeCertificateSelected}
                    aria-label="remove"
                  />
                </Flex>
              )}
            </FormControl>

            <FormControl mb={5} isInvalid={!!formik.errors.is_auto}>
              <Checkbox
                id="is_auto"
                name="is_auto"
                defaultIsChecked={formik.values.is_auto}
                onChange={formik.handleChange}
              >
                <Text>Режим автоторгов</Text>
              </Checkbox>
              <FormErrorMessage>{formik.errors.is_auto}</FormErrorMessage>
            </FormControl>

            {formik.values.is_auto === true && (
              <>
                <FormControl
                  isRequired
                  mb={5}
                  isInvalid={!!formik.errors.price_min}
                >
                  <FormLabel>Минимальное предложение</FormLabel>
                  <Input type="number" {...formik.getFieldProps('price_min')} />
                  <FormErrorMessage>{formik.errors.price_min}</FormErrorMessage>
                </FormControl>

                <FormControl mb={5} isInvalid={!!formik.errors.step}>
                  <FormLabel>Шаг скидки</FormLabel>
                  <InputGroup>
                    <Input
                      type="number"
                      readOnly
                      {...formik.getFieldProps('step')}
                    />
                    <InputRightAddon children="%" />
                  </InputGroup>
                  <FormErrorMessage>{formik.errors.step}</FormErrorMessage>
                </FormControl>

                <Slider
                  focusThumbOnChange={false}
                  defaultValue={1}
                  min={0.5}
                  max={5}
                  step={0.5}
                  onChange={(value) => formik.setFieldValue('step', value)}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb boxSize={5} />
                </Slider>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="submit" mr={3} isLoading={formik.isSubmitting}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={closeHandle}>
              Отмена
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}

export default UpdateModal;
