import React, { useEffect, useRef, useState } from 'react'
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import API from '@utils/api'
import { IconArrowLeft2, IconCheck, IconDownload } from '@utils/icons'
import moment from 'moment'
import { toast } from 'react-toastify'
import StatusLabel from '@components/StatusLabel'
import { ErrorParser } from '@utils/common'
import Update from '@components/Purchases/Update'
import NumberFormat from 'react-number-format'
import { isEmpty } from 'lodash-es'

const ConfirmCancelPurchaseModal = ({ isOpen, onClose, callback }) => {
  const cancelRef = useRef()

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Отмена закупки
          </AlertDialogHeader>

          <AlertDialogBody>
            Вы действительно хотите отменить закупку?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              onClick={() => {
                callback()
                onClose()
              }}
              mr={3}
            >
              Да
            </Button>
            <Button ref={cancelRef} onClick={onClose} variant='outline'>
              Нет
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

function PurchaseModal({ purchaseId, isOpen, onClose, fetchData }) {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({})
  const [isFileDownloading, setIsFileDownloading] = useState(false)
  const [isLoadingContractor, setIsLoadingContractor] = useState(null)

  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm,
  } = useDisclosure()

  const {
    isOpen: isOpenEdit,
    onClose: onCloseEdit,
    onOpen: onOpenEdit,
  } = useDisclosure()

  const onCloseHandler = () => {
    setData({})
    onClose()
  }

  const getFile = (fileName) => {
    setIsFileDownloading(true)
    API.purchase
      .getFile(fileName)
      .then((response) => {
        if (response.status === 200) {
          try {
            const a = document.createElement('a')
            const blob = new Blob([response.data], {
              type: response.headers['content-type'],
            })
            const url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = fileName
            a.click()
            window.URL.revokeObjectURL(url)
          } catch (e) {
            toast.error(`Ошибка! ${e}`)
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          return toast.error('Ошибка! У вас нет доступа')
        }
        if (error.response.status === 404) {
          return toast.error('Ошибка! Файл не найден')
        }
        return toast.error(`Ошибка ${error.response.status}. Повторите позже`)
      })
      .finally(() => setIsFileDownloading(false))
  }

  const handleCancel = () => {
    setIsLoading(true)
    API.purchase
      .cancel(purchaseId)
      .then(() => {
        toast.warning('Закупка отменена')
        fetchData()
        onCloseHandler()
      })
      .catch((error) => ErrorParser(error))
      .finally(() => setIsLoading(false))
  }

  const fetchPurchase = () => {
    API.purchase
      .view(purchaseId, { expand: 'status_label,orders' })
      .then((response) => {
        setData(response.data)
      })
      .catch((error) => {
        ErrorParser(error)
        if (error.response.status === 403) {
          onCloseHandler()
        }
      })
      .finally(() => setIsLoading(false))
  }

  const setContractor = (id) => {
    const data = new FormData()
    data.append('contractor_id', id)
    setIsLoadingContractor(id)
    API.purchase
      .setContractor(purchaseId, data)
      .then(() => fetchPurchase())
      .catch((error) => ErrorParser(error))
      .finally(() => setIsLoadingContractor(null))
  }

  const fetchFile = (fileName) => {
    API.order.getFile(fileName)
      .then((response) => {
        if (response.status === 200) {
          try {
            const a = document.createElement('a')
            const blob = new Blob([response.data], {
              type: response.headers['content-type'],
            })
            const url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = fileName
            a.click()
            window.URL.revokeObjectURL(url)
          } catch (e) {
            toast.error(`Ошибка! ${e}`)
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          return toast.error('Ошибка! У вас нет доступа')
        }
        if (error.response.status === 404) {
          return toast.error('Ошибка! Файл не найден')
        }
        return toast.error(`Ошибка ${error.response.status}. Повторите позже`)
      })
  }

  useEffect(() => {
    if (purchaseId && isOpen === true) {
      setIsLoading(true)

      fetchPurchase()
    }
  }, [purchaseId, isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onCloseHandler} size='full'>
      <ModalOverlay />
      <ModalContent borderRadius={0}>
        <ModalBody pt={10}>
          <Box maxW='1024px' m='auto'>
            <Flex mb={5}>
              <IconButton
                aria-label='close'
                mr={10}
                icon={<IconArrowLeft2 />}
                size='lg'
                variant='outline'
                onClick={onCloseHandler}
              />

              <Flex>
                <Heading mb={5}>
                  Закупка №
                  {data.id}
                </Heading>
              </Flex>
            </Flex>

            {isLoading && <Progress size='xs' isIndeterminate />}

            {!isLoading && (
              <>
                <Alert status='info' mb={10}>
                  Наименование объекта закупки:
                  {' '}
                  {data.name_object}
                </Alert>

                <ConfirmCancelPurchaseModal
                  isOpen={isOpenConfirm}
                  onClose={onCloseConfirm}
                  callback={handleCancel}
                />

                {data && (
                  <Update
                    isOpen={isOpenEdit}
                    onClose={onCloseEdit}
                    purchaseData={data}
                    fetchPurchase={fetchPurchase}
                    fetchPurchases={fetchData}
                  />
                )}

                {(data.status === 1 || data.status === 2) && (
                  <>
                    <Button mr={2} onClick={onOpenConfirm}>
                      Отменить закупку
                    </Button>

                    <Button variant='outline' onClick={onOpenEdit}>
                      Изменить закупку
                    </Button>
                  </>
                )}

                <Table mt={5} size='sm' variant='unstyled'>
                  <Tbody>
                    <Tr>
                      <Th>Статус закупки</Th>
                      <Td>
                        <StatusLabel value={data.status} />
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>Дата и время начала подачи заявок</Th>
                      <Td>{moment.unix(data.created_at).format('llll')}</Td>
                    </Tr>
                    <Tr>
                      <Th>Дата и время окончания подачи заявок</Th>
                      <Td>{moment(data.deadline_submission).format('llll')}</Td>
                    </Tr>
                    <Tr>
                      <Th>Дата и время начала торгов</Th>
                      <Td>{moment(data.trading_start_date).format('llll')}</Td>
                    </Tr>
                    <Tr>
                      <Th>Дата и время окончания торгов</Th>
                      <Td>
                        {data.trade_time_end
                          && moment.unix(data.trade_time_end).format('llll')}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>Только оригинал/Можно предложить эквивалент</Th>
                      <Td>
                        {data.exact_match === 1 && 'Только оригинал'}
                        {data.exact_match === 0
                          && 'Можно предложить эквивалент'}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>Приблизительная цена</Th>
                      <Td>
                        {Intl.NumberFormat('ru-RU', {
                          style: 'currency',
                          currency: 'RUB',
                        }).format(data.approximate_price)}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th>Описание</Th>
                      <Td>{data.description}</Td>
                    </Tr>
                    <Tr>
                      <Th>Объект закупки</Th>
                      <Td>
                        {data.files
                          && JSON.parse(data.files).map((fileName) => (
                            <Button
                              isLoading={isFileDownloading}
                              key={fileName}
                              onClick={() => getFile(fileName)}
                              variant='outline'
                              size='sm'
                              colorScheme='gray'
                              leftIcon={<IconDownload />}
                            >
                              {fileName}
                            </Button>
                          ))}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>

                <Text color='gray.400' fontSize={12} mt={5}>
                  Комиссия площадки за выигрыш в аукционе 2%
                </Text>

                <Text fontWeight={'bold'} mt={5} fontSize={20} mb={3}>Участники</Text>

                <Table w='100%' mb={5} size='sm'>
                  <Thead>
                    <Tr>
                      <Th>ID участника</Th>
                      <Th>Предложенная цена</Th>
                      <Th>Предложение</Th>
                      <Th>Дата предложения</Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {!isEmpty(data) && data.orders.map((order) => (
                      <Tr
                        key={order.id}
                      >
                        <Td>{order.user_id}</Td>
                        <Td>
                          <NumberFormat
                            displayType='text'
                            value={order.price_finish}
                            thousandSeparator
                            suffix=' ₽'
                            fixedDecimalScale
                            decimalScale={2}
                          />
                        </Td>
                        <Td>
                          <Link onClick={() => fetchFile(order.file_offer)} color='brand.red'>{order.file_offer}</Link>
                        </Td>
                        <Td>
                          {moment
                            .unix(order.updated_at)
                            .locale('ru')
                            .format('llll')}
                        </Td>
                        <Td>
                          {data.contractor_id !== order.user_id && (
                            <Button
                              size={'sm'}
                              variant={'outline'}
                              onClick={() => setContractor(order.user_id)}
                              isLoading={isLoadingContractor === order.user_id}
                            >
                              Выбрать поставщиком
                            </Button>
                          )}
                          {data.contractor_id === order.user_id && (
                            <Flex alignItems={'center'}>
                              <IconCheck mr={1} color='green.500' w={5} h={5} />
                              <Text fontWeight={'bold'}>Выбран поставщиком</Text>
                            </Flex>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PurchaseModal
