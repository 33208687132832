import React, { useEffect } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import {
  Box,
  chakra,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr, useColorModeValue,
} from '@chakra-ui/react';
import Pagination from '@components/DataTable/Pagination';
import { IconArrowDown, IconArrowUp } from '@utils/icons';

function DataTable({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  pageIndex: controlledPageIndex,
  status,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    gotoPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: controlledPageIndex, pageSize: localStorage.getItem('_tender_page_size') || 10 },
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    fetchData(pageSize, pageIndex + 1, sortBy, status);
  }, [pageIndex, pageSize, sortBy]);

  const bg = useColorModeValue('whiteAlpha.900', 'gray.800');

  return (
    <>
      <Flex mb={5}>
        <Box ml="auto">
          <Pagination
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </Box>
      </Flex>
      <Box position="relative">
        {loading && (
          <Box
            position="absolute"
            w="100%"
            top={0}
            bottom={0}
            bgColor={bg}
            opacity={0.5}
            zIndex={1}
          >
            <Box
              sx={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 6,
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <Spinner color="brand.red" />
            </Box>
          </Box>
        )}
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    isNumeric={column.isNumeric}
                  >
                    {column.render('Header')}
                    <chakra.span pl="4">
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <IconArrowDown aria-label="sorted descending" />
                        ) : (
                          <IconArrowUp aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()} position="relative">
            {page.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td
                      {...cell.getCellProps()}
                      isNumeric={cell.column.isNumeric}
                      fontSize={14}
                    >
                      {cell.render('Cell')}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      <Flex mt={5}>
        <Box ml="auto">
          <Pagination
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </Box>
      </Flex>
    </>
  );
}

export default DataTable;
