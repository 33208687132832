const labels = {
  id: 'ID',
  user_id: 'User ID',
  legal_address: 'Юридический адрес',
  actual_address: 'Фактический адрес',
  ogrn: 'ОГРН',
  inn: 'ИНН',
  kpp: 'КПП',
  checking_account: 'Расчетный счет',
  correspondence_account: 'Кор. счет',
  bank: 'Банк',
  bank_address: 'Адрес банка',
  bikk: 'БИКК',
  head_position: 'Должность руководителя',
  head_name: 'ФИО руководителя',
  registration_date: 'Дата регистрации организации',
  contact_person: 'Контактное лицо',
  additional_phone: 'Доп. телефон',
  organization_file: 'Карточка организации',
  organization_rules_file: 'Устав',
  egrul_file: 'Выписка из ЕГРЮЛ',
};

const label = (name) => labels[name];

export default label;
