import React, { useEffect, useRef } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/ru';
import * as yup from 'yup';
import { useFormik } from 'formik';
import API from '@utils/api';
import { forEach, isEmpty, map } from 'lodash-es';
import { humanFileSize } from '@utils/common';
import { IconAttach, IconCalendar, IconCheck, IconFile } from '@utils/icons';
import { toast } from 'react-toastify';

const RadioBtn = ({ name, formik }) => (
  <Button
    variant={formik.values.name_object === name ? 'solid' : 'outline'}
    onClick={() => formik.setFieldValue('name_object', name)}
    leftIcon={formik.values.name_object === name ? <IconCheck /> : null}
  >
    {name}
  </Button>
);

const RadioBtnSecurity = ({ label, value, formik }) => (
  <Button
    variant={formik.values.purchase_security === value ? 'solid' : 'outline'}
    onClick={() => formik.setFieldValue('purchase_security', value)}
    leftIcon={formik.values.purchase_security === value ? <IconCheck /> : null}
  >
    {label}
  </Button>
);

function Update({ isOpen, onClose, purchaseData, fetchPurchase = null, fetchPurchases = null }) {
  const FILE_SIZE = 1024 * 1024 * 20; // 20 МБ

  const fileRef = useRef(null);
  const securityFileRef = useRef(null);

  const validationSchema = yup.object().shape({
    deadline_submission: yup.string().required('Обязательное поле'),
    security_file: yup
      .mixed()
      .test(
        'securityFileRequired',
        'Прикрепите файл',
        () => {
          if (formik.values.purchase_security === 'balance') {
            return true;
          }
          return purchaseData.purchase_security_document || !isEmpty(securityFileRef.current.files);
        },
      )
      .test('securityFileSize', 'Размер файла не должен превышать 20МБ', () => {
        if (
          purchaseData.purchase_security_document
          || purchaseData.purchase_security === 'balance'
          || formik.values.purchase_security === 'balance'
        ) {
          return true;
        }

        if (!isEmpty(securityFileRef.current.files)) {
          return securityFileRef.current.files[0].size < FILE_SIZE;
        }
      }),
    files: yup
      .mixed()
      .test(
        'fileRequired',
        'Прикрепите файл',
        () => purchaseData.files || !isEmpty(fileRef.current.files),
      )
      .test('fileSize', 'Размер файла не должен превышать 20МБ', () => {
        if (purchaseData.files) {
          return true;
        }

        if (!isEmpty(fileRef.current.files)) {
          let flag = true;
          forEach(fileRef.current.files, (file) => {
            if (file.size > FILE_SIZE) {
              flag = false;
            }
          });
          return flag;
        }
      }),
  });

  const formik = useFormik({
    initialValues: {
      deadline_submission: purchaseData.deadline_submission || '',
      approximate_price: purchaseData.approximate_price || '',
      exact_match: purchaseData.exact_match.toString() || '1',
      description: purchaseData.description || '',
      name_object: purchaseData.name_object || '',
      purchase_security: purchaseData.purchase_security || '',
      security_file: purchaseData.purchase_security_document || null,
      files: [],
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { setErrors }) => {
      const data = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        if (key === 'files') {
          value.forEach((v) => {
            data.append('files[]', v);
          });
        } else {
          data.append(key, value);
        }
      });

      await API.purchase
        .update(purchaseData.id, data)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            toast.success('Закупка успешно обновлена');
            formik.resetForm();
            // Обновляем данные закупки
            if (typeof fetchPurchase === 'function') {
              fetchPurchase();
            }
            // Обновляем список "мои закупки"
            if (typeof fetchPurchases === 'function') {
              fetchPurchases();
            }
            onClose();
          } else {
            toast.error('Ошибка!');
            // eslint-disable-next-line no-console
            console.log('error', response);
          }
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            // Ошибка валидации

            const description = [];
            if (error.response?.data?.message) {
              const message = JSON.parse(error.response.data.message);
              setErrors(message);

              // eslint-disable-next-line no-restricted-syntax
              for (const [, value] of Object.entries(message)) {
                description.push(`- ${value[0] || value}`);
              }
              const html = `Ошибка!\n${description.join('\n')}`;
              return toast.error(html);
            }

            toast.error('Ошибка!');
          } else if (error.response?.status === 500) {
            // Ошибка сервера

            if (error.response?.data?.message) {
              toast.error(error.response.data.message);
            }
          } else toast.error('Неизвестная ошибка. Повторите позже');
        });
    },
  });

  const handleFileClick = () => {
    fileRef.current.click();
  };

  const handleSecurityFileClick = () => {
    securityFileRef.current.click();
  };

  const handleFileChange = (e) => {
    formik.setFieldValue('files', [...e.target.files]);
  };

  const handleSecurityFileChange = (e) => {
    console.log({ e });
    formik.setFieldValue('security_file', e.target.files[0]);
  };

  useEffect(() => {
    console.log({ purchaseData });
  }, [purchaseData]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent borderRadius={0}>
        <ModalCloseButton />
        <ModalBody pt={10}>
          <form onSubmit={formik.handleSubmit}>
            <Box maxW="1024px" m="auto">
              <Heading mb={5}>
                Изменить закупку №
                {purchaseData.id}
              </Heading>

              <FormControl isRequired mb={10}>
                <FormLabel>Наименование объекта закупки</FormLabel>
                <Stack direction="row">
                  <RadioBtn formik={formik} name="Оборудование" />
                  <RadioBtn formik={formik} name="Расходные материалы" />
                  <RadioBtn formik={formik} name="Анестезия" />
                </Stack>
              </FormControl>

              <Box display="none">
                <FormControl isRequired mb={formik.values.purchase_security === 'document' ? 5 : 10}>
                  <FormLabel mb={0}>Обеспечение закупки</FormLabel>
                  <Text color="gray.500" mb={3}>2% от цены закупки</Text>

                  <Stack direction="row">
                    <RadioBtnSecurity formik={formik} value="balance" label="Списать со счета" />
                    <RadioBtnSecurity formik={formik} value="document" label="Платежное поручение" />
                  </Stack>
                </FormControl>
              </Box>

              {formik.values.purchase_security === 'document' && (
                <FormControl isInvalid={formik.errors.security_file} mb={10}>
                  <Flex alignItems="center">
                    <IconButton
                      aria-label="file"
                      icon={<IconAttach />}
                      variant="outline"
                      onClick={handleSecurityFileClick}
                      mr={2}
                      size="lg"
                    />
                    <FormLabel>
                      Прикрепить платежное поручение (.doc, .pdf, .zip, .rar, .xls)
                    </FormLabel>
                  </Flex>

                  <Input
                    /* eslint-disable-next-line no-return-assign */
                    onClick={(e) => (e.target.value = null)}
                    ref={securityFileRef}
                    onChange={(e) => handleSecurityFileChange(e)}
                    type="file"
                    name="security_file"
                    sx={{ display: 'none' }}
                  />

                  {formik.values.security_file !== null && (
                  <Flex alignItems="center" mt={2}>
                    <IconFile />
                    <Text fontSize={13} ml={1}>
                      {formik.values.security_file.name}
                      {' '}
                      (
                      {humanFileSize(formik.values.security_file.size)}
                      )
                    </Text>
                  </Flex>
                  )}

                  <FormErrorMessage>{formik.errors.security_file}</FormErrorMessage>
                </FormControl>
              )}

              <Stack mb={10} direction="row" alignItems="start" spacing={5}>
                <FormControl
                  isRequired
                  isInvalid={formik.errors.deadline_submission}
                >
                  <FormLabel>Время окончания подачи заявок</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<IconCalendar color="gray.400" />}
                    />
                    <Input
                      type="text"
                      disabled
                      {...formik.getFieldProps('deadline_submission')}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Приблизительная цена (руб)</FormLabel>
                  <Input
                    type="number"
                    step="0.01"
                    min="0"
                    {...formik.getFieldProps('approximate_price')}
                  />
                </FormControl>
              </Stack>

              <RadioGroup
                onChange={(e) => formik.setFieldValue('exact_match', e)}
                value={formik.values.exact_match}
                mb={10}
              >
                <Stack direction="column">
                  <Radio value="1">Только оригинал</Radio>
                  <Radio value="0">Можно предложить эквивалент</Radio>
                </Stack>
              </RadioGroup>

              <FormControl mb={10}>
                <FormLabel>Описание</FormLabel>
                <Textarea {...formik.getFieldProps('description')} />
                <FormErrorMessage>error</FormErrorMessage>
              </FormControl>

              <Text fontWeight="semibold">Объект закупки</Text>
              <Text mb={5}>
                Заказчик указывает наименование товара в соответствии с
                упаковкой или декларацией
              </Text>

              <FormControl isInvalid={formik.errors.files} mb={10}>
                <Flex alignItems="center">
                  <IconButton
                    aria-label="file"
                    icon={<IconAttach />}
                    variant="outline"
                    onClick={handleFileClick}
                    mr={2}
                    size="lg"
                  />
                  <FormLabel>
                    Прикрепить (.doc, .pdf, .zip, .rar, .xls)
                  </FormLabel>
                </Flex>

                <Input
                  onClick={(e) => (e.target.value = null)}
                  ref={fileRef}
                  onChange={(e) => handleFileChange(e)}
                  type="file"
                  name="files"
                  multiple
                  sx={{ display: 'none' }}
                />

                {!isEmpty(formik.values.files)
                  && map(formik.values.files, (file) => (
                    <Flex alignItems="center" mt={2} key={file.name}>
                      <IconFile />
                      <Text fontSize={13} ml={1}>
                        {file.name}
                        {' '}
                        (
                        {humanFileSize(file.size)}
                        )
                      </Text>
                    </Flex>
                  ))}

                <FormErrorMessage>{formik.errors.files}</FormErrorMessage>
              </FormControl>

              <Text color="gray.500">
                <sup>*</sup>
                {' '}
                Комиссия площадки за успешно совершенную поставку
                2%
              </Text>

              <ButtonGroup mt={3} mb={5}>
                <Button size="lg" type="submit" isLoading={formik.isSubmitting}>
                  Сохранить
                </Button>
                <Button
                  size="lg"
                  variant="outline"
                  onClick={onClose}
                >
                  Отмена
                </Button>
              </ButtonGroup>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default Update;
