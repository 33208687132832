import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import OrganizationCard from '@components/OgranizationCard';
import OrganizationDocuments from '@components/OrganizationDocuments';

function Index({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Профиль организации</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs>
            <TabList>
              <Tab _focus={{ outline: 'none' }}>Профиль</Tab>
              <Tab _focus={{ outline: 'none' }}>Регистрационные документы</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <OrganizationCard />
              </TabPanel>
              <TabPanel>
                <OrganizationDocuments />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Закрыть
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default observer(Index);
