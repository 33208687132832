import React from 'react';
import { Box, Text } from '@chakra-ui/react';

function Index({ fontSize = 11, color = 'gray.500' }) {
  return (
    <Box>
      <Text fontSize={fontSize} color={color}>
        Версия
        {' '}
        {process.env.REACT_APP_VERSION}
      </Text>
    </Box>
  );
}

export default Index;
