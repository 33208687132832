import React, { Suspense, useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import Loader from '@components/Loader';
import Layout from '@components/Layout';
import Login from '@app/pages/Login';
import Main from '@app/pages/Main';
import '@fontsource/inter';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { isString } from 'lodash-es';
import NotFound from '@app/pages/NotFound';
import userStore from '@store/user';
import ProtectedRoute from '@app/pages/ProtectedRoute';
import Order from '@app/pages/Order';
import Signup from '@app/pages/Signup';
import { HelmetProvider } from 'react-helmet-async';
import Confirm from '@app/pages/Confirm';
import PasswordRemindRequest from '@app/pages/PasswordRemindRequest';
import PasswordReset from '@app/pages/PasswordReset';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import Centrifuge from 'centrifuge';
import appStore from '@store/app';
import Trade from '@app/pages/Trade';
import theme from './theme';

import 'react-toastify/dist/ReactToastify.css';
import './toast.css';

toast.configure({
  position: 'top-center',
  autoClose: 3000,
  draggable: false,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  pauseOnVisibilityChange: true,
  pauseOnHover: true,
  theme: 'colored',
});

const LoginRoute = () => {
  const { token } = userStore;

  return (
    <Route render={() => (isString(token) ? <Redirect to="/" /> : <Login />)} />
  );
};

const centrifuge = new Centrifuge(process.env.REACT_APP_WSS_URL);

function App() {
  const { token } = userStore;

  useEffect(() => {
    appStore.setCentrifugo(centrifuge);

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = () => {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_KEY_V3}`, () => {
      // eslint-disable-next-line no-console
      console.log('reCaptcha v3 script is loaded');
    });
  }, []);

  useEffect(() => {
    if (token) {
      centrifuge.setToken(userStore.token);
      centrifuge.connect();

      centrifuge.subscribe(`service#${userStore.user.id}`, (message) => {
        if (message?.data === 'update_profile') {
          userStore.getProfile();
        }
        if (message?.data === 'update_user') {
          userStore.getUser();
        }
      });
    }
  }, [token]);

  return (
    <HelmetProvider>
      <Suspense fallback={<Loader />}>
        <ChakraProvider theme={theme}>
          <Router>
            <Switch>
              <Route exact path="/">
                {token ? (
                  <ProtectedRoute path="/">
                    <Layout>
                      <Main />
                    </Layout>
                  </ProtectedRoute>
                ) : (
                  <Redirect to="/login" />
                )}
              </Route>

              <ProtectedRoute path="/order">
                <Order />
              </ProtectedRoute>

              <ProtectedRoute path="/trade/:id">
                <Trade />
              </ProtectedRoute>

              <Route path="/registration">
                <Signup />
              </Route>

              <LoginRoute path="/login" />

              <Route path="/confirm">
                <Confirm />
              </Route>

              <Route path="/remind-password-request">
                <PasswordRemindRequest />
              </Route>

              <Route path="/password-reset">
                <PasswordReset />
              </Route>

              <Route component={NotFound} />
            </Switch>
          </Router>
        </ChakraProvider>
      </Suspense>
    </HelmetProvider>
  );
}

export default observer(App);
