import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Skeleton,
  SkeletonCircle,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import userStore from '@store/user';
import { observer } from 'mobx-react-lite';
import logo from '@app/assets/logo-white.svg';
import { isEmpty } from 'lodash-es';
import ChangePassword from '@components/ChangePassword';
import Profile from '@components/Profile';
import Notifications from '@components/Notifications';
import {
  IconBalance,
  IconLock,
  IconLogout,
  IconMoon,
  IconNotificationsSettings,
  IconSun,
  IconUser,
} from '@utils/icons'
import NotificationsSettings from '@components/NotificationsSettings';
import BalaceModal from '@components/Balance';

function Index() {
  const [, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isEmpty(userStore.user)) {
      setIsLoaded(true);
    }
  }, [userStore.user]);

  const logout = () => {
    userStore.logout();
  };

  const { onOpen, onClose, isOpen } = useDisclosure();

  const {
    onOpen: onOpenProfile,
    onClose: onCloseProfile,
    isOpen: isOpenProfile,
  } = useDisclosure();

  const {
    onOpen: onOpenNotificationsSettings,
    onClose: onCloseNotificationsSettings,
    isOpen: isOpenNotificationsSettings,
  } = useDisclosure();

  const {
    onOpen: onOpenBalanceModal,
    onClose: onCloseBalanceModal,
    isOpen: isOpenBalanceModal,
  } = useDisclosure();

  const { toggleColorMode } = useColorMode();

  return (
    <Flex py={3} bg={useColorModeValue('brand.black', 'gray.800')}>
      <Flex maxW="1600px" w="100%" m="auto" alignItems="center" px={5}>
        <Box>
          <a href="https://stomtender.ru">
            <Image src={logo} alt="logo" maxW="200px" />
          </a>
        </Box>

        <Flex ml="auto">
          <IconButton
            ml={2}
            onClick={toggleColorMode}
            icon={useColorModeValue(<IconMoon />, <IconSun />)}
            aria-label="color-mode"
            size="sm"
            variant="ghost"
          />
        </Flex>

        <Flex ml={5}>
          <Notifications />
        </Flex>

        <Flex ml={5} alignItems="center">
          <Menu>
            <MenuButton>
              <Flex alignItems="center">
                <SkeletonCircle isLoaded={!!userStore.user.username} mr={2}>
                  <Avatar name={userStore.user.username} size="sm" />
                </SkeletonCircle>
                <Skeleton
                  isLoaded={!!userStore.user.username}
                  w={userStore.user.username ? 'auto' : '120px'}
                  h={userStore.user.username ? 'auto' : '24px'}
                >
                  <Text isTruncated maxW={300} color="white">
                    <b>{userStore.user.username}</b>
                  </Text>
                </Skeleton>
              </Flex>
            </MenuButton>
            <MenuList fontSize={14}>
              <MenuItem onClick={onOpenProfile} icon={<IconUser />}>
                Профиль
              </MenuItem>
              <MenuItem onClick={onOpen} icon={<IconLock />}>
                Сменить пароль
              </MenuItem>
              <MenuItem onClick={onOpenNotificationsSettings} icon={<IconNotificationsSettings />}>
                Настройка уведомлений
              </MenuItem>
              <MenuItem onClick={onOpenBalanceModal} icon={<IconBalance />}>
                Баланс
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={logout} icon={<IconLogout />}>
                Выйти
              </MenuItem>
            </MenuList>
          </Menu>

          <ChangePassword isOpen={isOpen} onClose={onClose} />
          <Profile isOpen={isOpenProfile} onClose={onCloseProfile} />
          <NotificationsSettings isOpen={isOpenNotificationsSettings} onClose={onCloseNotificationsSettings} />
          <BalaceModal isOpen={isOpenBalanceModal} onClose={onCloseBalanceModal} />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default observer(Index);
