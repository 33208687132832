import React, { useEffect, useState } from 'react'
import {
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react'
import API from '@utils/api'
import moment from 'moment'
import AttachFile from '@components/Balance/AttachFile'

function Index({ isOpen, onClose }) {
  const [balanceHistory, setBalanceHistory] = useState([])
  const [balance, setBalance] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchBalanceHistory = () => {
    setIsLoading(true)
    API.user.balanceHistory().then((response) => {
      setBalance(response.data.balance)
      if (response.data.balance_history) {
        setBalanceHistory(response.data.balance_history)
      }
    }).finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (isOpen) {
      fetchBalanceHistory()
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Баланс</ModalHeader>
        <ModalCloseButton />
        <ModalBody mb={5}>
          {isLoading && <Progress size={'sm'} isIndeterminate />}
          {!isLoading && (
            <>
              <Text>Свободных средств: <b>{balance}</b> ₽</Text>

              {balanceHistory.length > 0 && (
                <>
                  <Text fontWeight={'bold'} mt={5} mb={2}>Зарезервировано:</Text>
                  <Table size={'sm'} mb={2}>
                    <Tbody>
                      {balanceHistory.map((item) => (
                        <Tr key={item.id}>
                          <Td><Text whiteSpace={'nowrap'}>Закупка №{item.purchase_id}</Text></Td>
                          <Td><Text whiteSpace={'nowrap'}>{item.sum} ₽</Text></Td>
                          <Td textAlign={'right'}>
                            {moment
                              .unix(item.created_at)
                              .locale('ru')
                              .format('lll')}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>

                  <Text fontSize={14} mt={10} mb={4}>Для возврата средств прикрепите заявление <Link
                    href='https://stomtender.ru/uploads/files/forma-pisma-o-vozvrashchenii-platezha-s-litsevogo-scheta.pdf'
                    color={'red.600'}>по форме</Link></Text>

                  <AttachFile callback={onClose} />

                </>
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default Index
