import React from 'react';
import purchaseStore from '@store/purchase';
import { Badge } from '@chakra-ui/react';

function StatusLabel({ value }) {
  return (
    <Badge
      variant="solid"
      colorScheme={
        value === 0
          ? 'red'
          : value === 1
            ? 'blue'
            : value === 2
              ? 'yellow'
              : value === 3
                ? 'green'
                : value === 4
                  ? 'cyan'
                  : 'gray'
      }
    >
      {purchaseStore.statusList[value] || value}
    </Badge>
  );
}

export default StatusLabel;
