import React, { Suspense, useState } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';
import API from '@utils/api';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { has } from 'lodash-es';
import Loader from '@components/Loader';
import userStore from '@store/user';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Version from '@components/Version';
import { IconEyeClose, IconEyeOpen, IconLock, IconUser } from '@utils/icons';
import { toast } from 'react-toastify';

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup.string().email('Не верный формат email').required('Обязательное поле'),
    password: yup.string().required('Обязательное поле'),
  });

  const handleShowClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Suspense fallback={<Loader />}>
      <Helmet>
        <title>Вход в личный кабинет – Stom Tender</title>
      </Helmet>

      <Formik
        initialValues={{
          email: '',
          password: '',
          rememberMe: true,
        }}
        validateOnChange={false}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors }) => {
          setLoginError(false);

          const reCaptcha = await window.grecaptcha.execute(process.env.REACT_APP_KEY_V3, { action: 'homepage' });

          if (!reCaptcha) {
            toast.warning('Капча не готова. Перезагрузите страницу.');
            return;
          }

          const loginData = new FormData();

          loginData.append('email', values.email);
          loginData.append('password', values.password);
          loginData.append('rememberMe', values.rememberMe);
          loginData.append('reCaptcha', reCaptcha);

          await API.user
            .login(loginData)
            .then((response) => {
              if (response.status !== 200) {
                setLoginError(true);

                // eslint-disable-next-line no-console
                console.log('error', response);
              } else if (response.status === 200 && has(response.data, 'access_token')) {
                userStore.setToken(response.data.access_token);
              }
            })
            .catch((error) => {
              if (error.response.status === 422) {
                const message = JSON.parse(error.response.data.message);
                if (has(message, 'password')) {
                  return setLoginError(true);
                }

                if (has(message, 'email')) {
                  return setErrors(message);
                }

                if (has(message, 'reCaptcha')) {
                  return toast.warning('Кажется, вы робот... повторите позже.');
                }
              }

              // eslint-disable-next-line no-console
              console.log('error', error.response);
            });
        }}
      >
        {({ values, errors, handleChange, isSubmitting, setFieldValue }) => (
          <Flex
            flexDirection="column"
            width="100wh"
            height="100vh"
            bg={useColorModeValue('gray.200', 'gray.600')}
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              flexDir="column"
              mb="2"
              justifyContent="center"
              alignItems="center"
            >
              <Heading color={useColorModeValue('brand.blueBlack', 'gray.400')}>Вход</Heading>
              <Box minW={{ base: '90%', md: '468px' }}>
                <Form>
                  <Stack
                    spacing={4}
                    p="2rem"
                    bg={useColorModeValue('whiteAlpha.900', 'gray.800')}
                    boxShadow="md"
                  >
                    <FormControl isInvalid={errors.email} mb={5}>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<IconUser color="gray.300" />}
                        />
                        <Input
                          type="text"
                          placeholder="Email"
                          value={values.email}
                          name="email"
                          onChange={handleChange}
                        />
                      </InputGroup>
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.password}>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="gray.300"
                          children={<IconLock color="gray.300" />}
                        />
                        <Input
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Пароль"
                          name="password"
                          onChange={handleChange}
                          value={values.password}
                        />
                        <InputRightElement>
                          <IconButton
                            variant="ghost"
                            size="sm"
                            onClick={handleShowClick}
                            icon={
                              showPassword ? (
                                <IconEyeClose />
                              ) : (
                                <IconEyeOpen />
                              )
                            }
                            aria-label="Показать пароль"
                          />
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>{errors.password}</FormErrorMessage>

                      <Flex justifyContent="space-between" mb={3} mt={2}>
                        <FormHelperText>
                          <Checkbox
                            onChange={(e) => setFieldValue('rememberMe', e.target.checked)}
                            name="rememberMe"
                            defaultChecked={values.rememberMe}
                          >
                            <Text fontSize={14}>Запомнить меня</Text>
                          </Checkbox>
                        </FormHelperText>

                        <FormHelperText textAlign="right">
                          <Link as={RouterLink} to="/remind-password-request">Забыли пароль?</Link>
                        </FormHelperText>
                      </Flex>
                    </FormControl>

                    {loginError && (
                      <Alert status="error">
                        <AlertIcon />
                        Неверный логин или пароль
                      </Alert>
                    )}

                    <Button
                      type="submit"
                      variant="solid"
                      width="full"
                      isLoading={isSubmitting}
                    >
                      Войти
                    </Button>
                  </Stack>
                </Form>
              </Box>
            </Stack>

            <Box mt={2}>
              Нет аккаунта?
              {' '}
              <Link as={RouterLink} to="/registration" color={useColorModeValue('brand.blueDark', 'gray.400')} fontWeight={600}>Зарегистрировать</Link>
            </Box>

            <Box mt={5} mb={5}>
              <Version />
            </Box>
          </Flex>
        )}
      </Formik>
    </Suspense>
  );
}

export default Login;
