import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Text,
} from '@chakra-ui/react'
import { AiOutlineDelete } from 'react-icons/ai'
import * as yup from 'yup'
import API from '@utils/api'
import { IconAttach } from '@utils/icons'
import { toast } from 'react-toastify'
import { ErrorParser } from '@utils/common'
import { isFunction } from 'lodash-es'

const FILE_SIZE = 1024 * 1024 * 20 // 20 МБ

function AttachFile({ callback = null, notice = 'Поддерживаемые форматы: .doc, .pdf, .zip, .rar, .xls' }) {
  const fileRef = useRef(null)

  const [isInvalid, setIsInvalid] = useState(false)
  const [selectedFile, setSelectedFile] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const validationSchema = yup.object().shape({
    file: yup
      .mixed()
      .test(
        'fileSize',
        'Размер файла не должен превышать 20МБ',
        () => !selectedFile
          || (selectedFile
            && selectedFile.size <= FILE_SIZE),
      ),
  })

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0])
  }

  const clickHandle = () => {
    fileRef.current.click()
  }

  const removeSelected = () => {
    setIsInvalid(false)
    setErrorMessage('')
    setSelectedFile(null)
  }

  const uploadFile = () => {
    setIsLoading(true)

    const data = new FormData()

    data.append('file', selectedFile)

    API.user.balanceCheckout(data)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Заявка отправлена')
          removeSelected()
          if (isFunction(callback)) {
            callback()
          }
        }
      })
      .catch((error) => ErrorParser(error))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (selectedFile !== '') {
      setErrorMessage('')
      setIsInvalid(false)
      validationSchema.validate({
        file: selectedFile,
      }).catch((err) => {
        setIsInvalid(true)
        setErrorMessage(err.errors)
      })
    }
  }, [selectedFile])

  return (
    <FormControl isInvalid={isInvalid}>
      <Flex alignItems='center'>
        <IconButton
          aria-label='file'
          icon={<IconAttach />}
          variant='outline'
          onClick={() => clickHandle()}
          mr={2}
          size='lg'
        />
        <FormLabel>Заявление на возврат средств<br/><FormHelperText>{notice}</FormHelperText></FormLabel>
      </Flex>

      <Input
        ref={fileRef}
        onClick={(e) => e.target.value = null}
        onChange={(e) => handleFileChange(e)}
        type='file'
        sx={{ display: 'none' }}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
      {selectedFile && (
        <Flex alignItems='center' mt={2}>
          <Text fontSize={13}>
            {selectedFile?.name}
          </Text>
          <IconButton
            icon={<AiOutlineDelete />}
            variant='outline'
            size='xs'
            ml={2}
            onClick={removeSelected}
            aria-label='remove'
          />
        </Flex>
      )}

      {!isInvalid && selectedFile && (
        <Flex justifyContent={'end'} mt={4}>
            <Button onClick={uploadFile} isLoading={isLoading} mr={2}>Вывести</Button>
            <Button onClick={uploadFile} variant={'ghost'} onClick={callback}>Отмена</Button>
        </Flex>)}
    </FormControl>
  )
}

export default AttachFile
