import { extendTheme, withDefaultProps } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  tablet: '1200px',
  fullHd: '1400px',
});

const overrides = {
  breakpoints,
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    body: 'Inter',
  },
  colors: {
    brand: {
      red: '#B8002E',
      yellow: '#CCC014',
      blueLight: '#149DCC',
      blueDark: '#066180',
      blueBlack: '#283043',
      black: '#333333',
    },
    red: {
      500: '#B8002E',
    },
  },
  components: {
    Modal: {
      sizes: {
        xl: {
          ModalContent: {
            maxWidth: '1000px',
          },
        },
      },
    },
  },
};

const theme = extendTheme(overrides, withDefaultProps({
  defaultProps: {
    colorScheme: 'red',
  },
}));

export default theme;
