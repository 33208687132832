import React, { Suspense, useState } from 'react';
import Loader from '@components/Loader';
import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import API from '@utils/api';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';
import { IconUser } from '@utils/icons';
import { toast } from 'react-toastify';

const Success = ({ email }) => (
  <Box
    maxW="600px"
    backgroundColor="whiteAlpha.900"
    boxShadow="md"
    mt={3}
    p={5}
    px={10}
  >
    <Text>
      На адрес
      {' '}
      <b>{email}</b>
      {' '}
      отправлено письмо для сброса пароля
    </Text>
  </Box>
);

function PasswordRemindRequest() {
  const [isSend, setIsSend] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Не верный формат email')
      .required('Обязательное поле'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { setErrors }) => {
      const reCaptcha = await window.grecaptcha.execute(process.env.REACT_APP_KEY_V3, { action: 'homepage' });

      if (!reCaptcha) {
        toast.warning('Капча не готова. Перезагрузите страницу.');
        return;
      }

      const data = new FormData();
      data.append('email', values.email);
      data.append('reCaptcha', reCaptcha);

      await API.user
        .remind(data)
        .then((response) => {
          if (response.status === 200 && response.data === 'true') {
            setIsSend(true);
          }
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            if (error.response?.data?.message) {
              const message = JSON.parse(error.response.data.message);
              setErrors(message);
            }
          } else {
            toast.error('Ошибка сервера. Повторите позже');
          }
        });
    },
  });

  return (
    <Suspense fallback={<Loader />}>
      <Helmet>
        <title>Восстановление пароля – Stom Tender</title>
      </Helmet>
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        bg={useColorModeValue('gray.200', 'gray.600')}
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
        >
          <Heading color={useColorModeValue('brand.blueBlack', 'gray.400')}>Восстановление пароля</Heading>
          <Box minW={{ base: '90%', md: '520px' }} maxW={520}>
            {isSend && <Success email={formik.values.email} /> }

            {!isSend && (
            <form onSubmit={formik.handleSubmit}>
              <Stack
                spacing={4}
                p="2rem"
                bg={useColorModeValue('whiteAlpha.900', 'gray.800')}
                boxShadow="md"
              >
                <Text fontSize={14}>
                  Введите почту, на которую будет выслано письмо с инструкцией
                  для восстановления пароля
                </Text>
                <FormControl isRequired isInvalid={formik.errors.email} mb={5}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<IconUser color="gray.300" />}
                    />
                    <Input
                      type="text"
                      placeholder="Email"
                      {...formik.getFieldProps('email')}
                    />
                  </InputGroup>
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>

                <Button
                  type="submit"
                  variant="solid"
                  width="full"
                  isLoading={formik.isSubmitting}
                >
                  Отправить
                </Button>
              </Stack>
            </form>
            )}
          </Box>
        </Stack>

        <Box mt={2}>
          <Link
            as={RouterLink}
            to="/login"
            color={useColorModeValue('brand.blueDark', 'gray.400')}
            fontWeight={600}
          >
            Вход в кабинет
          </Link>
        </Box>
      </Flex>
    </Suspense>
  );
}

export default PasswordRemindRequest;
