import React, { useEffect, useMemo, useState } from 'react';
import API from '@utils/api';
import DataTable from '@components/DataTable/Table';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Select,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import moment from 'moment';
import { isEmpty, map } from 'lodash-es';
import ViewModal from '@components/Purchases/View';
import {
  IconCheck,
  IconFavouriteFill,
  IconFavouriteOutline,
  IconPrint,
} from '@utils/icons';
import { toast } from 'react-toastify';
import { ErrorParser } from '@utils/common';
import purchaseStore from '@store/purchase';
import userStore from '@store/user';
import { observer } from 'mobx-react-lite';
import appStore from '@store/app';
import StatusLabel from '@components/StatusLabel';
import { useHistory } from 'react-router-dom';

function prepareParams(data) {
  const params = {};

  if (data.pageIndex) {
    params.page = data.pageIndex;
  }

  if (data.pageSize) {
    params['per-page'] = data.pageSize;
  }

  if (!isEmpty(data.sortBy)) {
    let field = data.sortBy[0].id;
    if (data.sortBy[0].desc === true) {
      field = `-${field}`;
    }
    params.sort = field;
  }

  if (data.status !== 'all') {
    params['filter[status]'] = data.status;
  }

  params['filter[user_id][!=]'] = userStore.user.id;

  params.expand = 'user,is_favourite,is_ordered';

  return params;
}

function Index({ trigger }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [statusState, setStatusState] = useState('all');
  const [purchaseId, setPurchaseId] = useState(false);
  const [addFavouriteIsLoading, setAddFavouriteIsLoading] = useState(false);
  const [filter, setFilter] = useState('all');

  const history = useHistory();

  const { onOpen, isOpen, onClose } = useDisclosure();

  const viewPurchase = (id) => {
    setPurchaseId(id);
    onOpen();
  };

  const fetchData = (pageSize, pageIndex, sortBy, status = 'all') => {
    setIsLoading(true);

    purchaseStore.setPageIndex(pageIndex);
    purchaseStore.setPageSize(pageSize);
    purchaseStore.setSortBy(sortBy);

    setStatusState(status);

    const params = prepareParams({ pageSize, pageIndex, sortBy, status });

    API.purchase
      .list(params)
      .then((response) => {
        purchaseStore.setPageCount(
          parseFloat(response.headers['x-pagination-page-count']),
        );
        setData(response.data);
      })
      .catch(() => toast.error('Не удалось получить список закупок'))
      .finally(() => setIsLoading(false));
  };

  const removeFavourite = (id) => {
    setAddFavouriteIsLoading(true);
    API.purchase
      .removeFavourite(id)
      .then((response) => {
        if (response.data === 'true') {
          toast.warning('Удалено из избранного');
        } else {
          toast.error('Не удалось удалить из избранного');
        }
      })
      .catch((error) => ErrorParser(error))
      .finally(() => {
        setAddFavouriteIsLoading(false);
        fetchData(
          purchaseStore.pageSize,
          purchaseStore.pageIndex,
          purchaseStore.sortBy,
        );
      });
  };

  const addFavourite = (id) => {
    setAddFavouriteIsLoading(true);
    API.purchase
      .addFavourite(id)
      .then((response) => {
        if (response.data === 'true') {
          toast.success('Добавлено в избранное');
        } else {
          toast.error('Не удалось добавить в избранное');
        }
      })
      .catch((error) => ErrorParser(error))
      .finally(() => {
        setAddFavouriteIsLoading(false);
        fetchData(
          purchaseStore.pageSize,
          purchaseStore.pageIndex,
          purchaseStore.sortBy,
        );
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Номер',
        accessor: 'id',
      },
      {
        Header: 'Заказчик',
        id: 'user_id',
        accessor: 'user.username',
      },
      {
        Header: 'Объект закупки',
        accessor: 'name_object',
      },
      {
        Header: 'Цена',
        accessor: 'approximate_price',
        Cell: ({ value }) => Intl.NumberFormat("ru-RU", {
          style: "currency",
          currency: "RUB",
        }).format(value),
      },
      {
        Header: 'Статус',
        accessor: 'status',
        Cell: ({ value }) => <StatusLabel value={value} />,
      },
      {
        Header: 'Дата окончания подачи заявок',
        accessor: 'deadline_submission',
        Cell: (props) => moment(props.value).format('lll'),
      },
      {
        Header: 'Дата проведения торгов',
        accessor: 'trading_start_date',
        Cell: (props) => moment(props.value).format('lll'),
      },
      {
        Header: 'Навигация',
        Cell: ({ row }) => (
          <Flex>
            <Button
              onClick={() => {
                if (row.original.status === 3 || row.original.status === 4) {
                  history.push(`/trade/${row.original.id}`);
                } else {
                  viewPurchase(row.original.id);
                }
              }}
              size="sm"
              variant="outline"
              colorScheme="gray"
              mr={5}
            >
              Просмотр
            </Button>
            <Tooltip
              label={
                row.original.is_favourite ? 'Из избранного' : 'В избранное'
              }
            >
              <IconButton
                aria-label="favourite"
                icon={
                  row.original.is_favourite ? (
                    <IconFavouriteFill />
                  ) : (
                    <IconFavouriteOutline />
                  )
                }
                size="sm"
                variant="outline"
                colorScheme={row.original.is_favourite ? 'red' : 'gray'}
                ml={1}
                onClick={() => (row.original.is_favourite
                  ? removeFavourite(row.original.id)
                  : addFavourite(row.original.id))}
                isLoading={addFavouriteIsLoading}
              />
            </Tooltip>
            <Tooltip label="Распечатать">
              <IconButton
                aria-label="print"
                icon={<IconPrint />}
                size="sm"
                variant="outline"
                colorScheme="gray"
                ml={1}
              />
            </Tooltip>
            {row.original.is_ordered === true && (
              <Tooltip label="Заявка подана">
                <Flex alignItems="center" w={6} h={6}>
                  <IconCheck w={6} h={6} color="green" ml={5} />
                </Flex>
              </Tooltip>
            )}
          </Flex>
        ),
      },
    ],
    [purchaseStore.statusList],
  );

  const statusFilterHandler = (e) => {
    setFilter(e.target.value);
    fetchData(
      purchaseStore.pageSize,
      purchaseStore.pageIndex,
      purchaseStore.sortBy,
      e.target.value,
    );
  };

  useEffect(() => purchaseStore.setPageIndex(0), [trigger]);

  useEffect(() => {
    // Update data
    appStore.centrifugo.subscribe('common_service', (message) => {
      if (message?.data === 'update_purchases') {
        fetchData(
          purchaseStore.pageSize,
          purchaseStore.pageIndex,
          purchaseStore.sortBy,
          filter,
        );
      }
    });

    if (isEmpty(purchaseStore.statusList)) {
      purchaseStore.fetchStatusList();
    }
  }, []);

  return (
    <>
      {purchaseId && (
        <ViewModal
          isOpen={isOpen}
          onClose={onClose}
          purchaseId={purchaseId}
          showButtons
        />
      )}
      {!isEmpty(purchaseStore.statusList) && (
        <Flex
          mb={purchaseStore.pageCount > 1 ? -10 : 5}
          mt={5}
          alignItems="center"
        >
          <Box>
            <Select onChange={statusFilterHandler}>
              <option value="all">Все</option>
              {map(purchaseStore.statusList, (item, id) => (
                <option key={id} value={id}>
                  {item}
                </option>
              ))}
            </Select>
          </Box>
        </Flex>
      )}
      {trigger === true && (
        <DataTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          pageCount={purchaseStore.pageCount}
          pageIndex={purchaseStore.pageIndex}
          loading={isLoading}
          status={statusState}
        />
      )}
    </>
  );
}

export default observer(Index);
