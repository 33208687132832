import React, { useEffect, useMemo, useState } from 'react';
import API from '@utils/api';
import DataTable from '@components/DataTable/Table';
import {
  Box,
  Button,
  Flex,
  Select, Text,
  useDisclosure,
} from '@chakra-ui/react';
import moment from 'moment';
import { isEmpty, map } from 'lodash-es';
import PurchaseModal from '@components/Purchases/My/view';
import userStore from '@store/user';
import { toast } from 'react-toastify';
import purchaseStore from '@store/purchase';
import { observer } from 'mobx-react-lite';
import StatusLabel from '@components/StatusLabel';
import appStore from '@store/app';

function prepareParams(data) {
  const params = {};

  if (data.pageIndex) {
    params.page = data.pageIndex;
  }

  if (data.pageSize) {
    params['per-page'] = data.pageSize;
  }

  if (!isEmpty(data.sortBy)) {
    let field = data.sortBy[0].id;
    if (data.sortBy[0].desc === true) {
      field = `-${field}`;
    }
    params.sort = field;
  }

  if (data.status !== 'all') {
    params['filter[status]'] = data.status;
  }

  if (userStore.user?.id) {
    params['filter[user_id]'] = userStore.user.id;
  }

  return params;
}

function Index({ trigger }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndexState, setPageIndex] = useState(0);
  const [pageSizeState, setPageSizeState] = useState(0);
  const [sortByState, setSortByState] = useState({});
  const [statusState, setStatusState] = useState('all');
  const [purchaseId, setPurchaseId] = useState(false);

  const { onOpen, isOpen, onClose } = useDisclosure();

  const viewPurchase = (id) => {
    setPurchaseId(id);
    onOpen();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Номер',
        accessor: 'id',
      },
      {
        Header: 'Объект закупки',
        accessor: 'name_object',
      },
      {
        Header: 'Цена',
        accessor: 'approximate_price',
        isNumeric: true,
        Cell: ({ value }) => Intl.NumberFormat("ru-RU", {
          style: "currency",
          currency: "RUB",
        }).format(value),
      },
      {
        Header: 'Статус',
        accessor: 'status',
        Cell: ({ value }) => <StatusLabel value={value} />,
      },
      {
        Header: 'Дата окончания подачи заявок',
        accessor: 'deadline_submission',
        Cell: (props) => moment(props.value).format('lll'),
      },
      {
        Header: 'Дата проведения торгов',
        accessor: 'trading_start_date',
        Cell: (props) => moment(props.value).format('lll'),
      },
      {
        Header: 'Навигация',
        Cell: (props) => (
          <Button
            onClick={() => viewPurchase(props.row.original.id)}
            size="sm"
            variant="outline"
            colorScheme="gray"
          >
            Просмотр
          </Button>
        ),
      },
    ],
    [purchaseStore.statusList],
  );

  const fetchData = (pageSize, pageIndex, sortBy, status = 'all') => {
    setIsLoading(true);

    setSortByState(sortBy);
    setPageSizeState(pageSize);
    setStatusState(status);

    const params = prepareParams({ pageSize, pageIndex, sortBy, status });

    API.purchase
      .list(params)
      .then((response) => {
        setPageCount(parseFloat(response.headers['x-pagination-page-count']));
        setPageIndex(
          parseFloat(response.headers['x-pagination-current-page']) - 1,
        );
        setData(response.data);
      })
      .catch(() => toast.error('Не удалось получить список закупок'))
      .finally(() => setIsLoading(false));
  };

  const statusFilterHandler = (e) => fetchData(pageSizeState, pageIndexState, sortByState, e.target.value);

  useEffect(() => {
    // Update data
    appStore.centrifugo.subscribe('common_service', (message) => {
      if (message?.data === 'update_purchases') {
        fetchData(
          purchaseStore.pageSize,
          purchaseStore.pageIndex,
          purchaseStore.sortBy,
        );
      }
    });
  }, []);

  return (
    <>
      {purchaseId && (
        <PurchaseModal
          isOpen={isOpen}
          onClose={onClose}
          purchaseId={purchaseId}
          fetchData={() => fetchData(pageSizeState, pageIndexState, sortByState)}
        />
      )}

      {!isEmpty(purchaseStore.statusList) && (
        <Flex mb={pageCount > 1 ? -10 : 5} mt={5} alignItems="center">
          <Box>
            <Select onChange={statusFilterHandler}>
              <option value="all">Все</option>
              {map(purchaseStore.statusList, (item, id) => (
                <option key={id} value={id}>
                  {item}
                </option>
              ))}
            </Select>
          </Box>
        </Flex>
      )}
      {trigger === true && (
        <DataTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          pageCount={pageCount}
          pageIndex={pageIndexState}
          loading={isLoading}
          status={statusState}
        />
      )}
    </>
  );
}

export default observer(Index);
