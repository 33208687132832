import React, { useState } from 'react';
import {
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import API from '@utils/api';
import { AiFillEye, AiFillEyeInvisible, AiOutlineLock } from 'react-icons/ai';
import { toast } from 'react-toastify';

const CAiOutlineLock = chakra(AiOutlineLock);
const CAiFillEye = chakra(AiFillEye);
const CAiFillEyeInvisible = chakra(AiFillEyeInvisible);

function Index({ isOpen, onClose }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = yup.object().shape({
    password: yup.string().required('Обязательное поле'),
    passwordNew: yup
      .string()
      .min(6, 'Минимальная длина 6 символов')
      .required('Обязательное поле'),
    passwordRepeat: yup
      .string()
      .oneOf([yup.ref('passwordNew'), null], 'Пароли не совпадают'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordNew: '',
      passwordRepeat: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { setErrors, resetForm }) => {
      const data = new FormData();

      data.append('password', values.password);
      data.append('passwordNew', values.passwordNew);

      await API.user
        .passwordNew(data)
        .then((response) => {
          if (response.status === 200 && response.data === 'true') {
            toast.success('Пароль успешно сменен!');
            resetForm();
            onClose();
          }
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            if (error.response?.data?.message) {
              const message = JSON.parse(error.response.data.message);
              setErrors(message);
            }
          } else {
            toast.error('Ошибка сервера. Повторите позже');
          }
        });
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={formik.handleSubmit}>
        <ModalContent>
          <ModalHeader>Сменить пароль</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isInvalid={formik.errors.password} mb={5}>
              <FormLabel>Текущий пароль</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  children={<CAiOutlineLock color="gray.300" />}
                />
                <Input
                  type={showPassword ? 'text' : 'password'}
                  {...formik.getFieldProps('password')}
                />
                <InputRightElement>
                  <IconButton
                    variant="ghost"
                    size="sm"
                    onClick={handleShowClick}
                    icon={
                      showPassword ? <CAiFillEye /> : <CAiFillEyeInvisible />
                    }
                    aria-label="Показать пароль"
                    tabIndex={-1}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formik.errors.passwordNew} mb={5}>
              <FormLabel>Новый пароль</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  children={<CAiOutlineLock color="gray.300" />}
                />
                <Input
                  type={showPassword ? 'text' : 'password'}
                  {...formik.getFieldProps('passwordNew')}
                />
                <InputRightElement>
                  <IconButton
                    variant="ghost"
                    size="sm"
                    onClick={handleShowClick}
                    icon={
                      showPassword ? <CAiFillEye /> : <CAiFillEyeInvisible />
                    }
                    aria-label="Показать пароль"
                    tabIndex={-1}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formik.errors.passwordNew}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formik.errors.passwordRepeat}>
              <FormLabel>Повторить новый пароль</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  children={<CAiOutlineLock color="gray.300" />}
                />
                <Input
                  type={showPassword ? 'text' : 'password'}
                  {...formik.getFieldProps('passwordRepeat')}
                />
                <InputRightElement>
                  <IconButton
                    variant="ghost"
                    size="sm"
                    onClick={handleShowClick}
                    icon={
                      showPassword ? <CAiFillEye /> : <CAiFillEyeInvisible />
                    }
                    aria-label="Показать пароль"
                    tabIndex={-1}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {formik.errors.passwordRepeat}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              mr={3}
              isLoading={formik.isSubmitting}
            >
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Отмена
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}

export default Index;
