import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import userStore from '@store/user';
import API from '@utils/api';
import { observer } from 'mobx-react-lite';
import { find } from 'lodash-es';
import AttachFile from '@components/OrganizationDocuments/AttachFile';
import moment from 'moment';
import { toast } from 'react-toastify';
import { IconDownload } from '@utils/icons';

const style = {
  td: {
    fontSize: 14,
  },
};

function Index() {
  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const [documentTypes, setDocumentTypes] = useState({});

  const getFile = (fileName) => {
    setIsFileDownloading(true);
    API.user
      .getFile(fileName)
      .then((response) => {
        if (response.status === 200) {
          try {
            const a = document.createElement('a');
            const blob = new Blob([response.data], {
              type: response.headers['content-type'],
            });
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
          } catch (e) {
            toast.error(`Ошибка! ${e}`);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          return toast.error('Ошибка! У вас нет доступа');
        }
        if (error.response.status === 404) {
          return toast.error('Ошибка! Файл не найден');
        }
        return toast.error(`Ошибка ${error.response.status}. Повторите позже`);
      })
      .finally(() => setIsFileDownloading(false));
  };

  // Callback for AttachFile
  const uploaded = () => {
    userStore.getProfile();
  };

  useEffect(() => {
    API.user.getDocumentTypes().then((response) => {
      if (response.status === 200) {
        setDocumentTypes(response.data);
      }
    });
  }, []);

  return (
    <div>
      <Table mt={5} size="md">
        <Thead>
          <Tr>
            <Th width={300}>Документ</Th>
            <Th>Файл</Th>
            <Th width={250}>Дата загрузки</Th>
          </Tr>
        </Thead>
        <Tbody>
          {documentTypes
            && Object.entries(documentTypes).map(([i, type]) => {
              const fileObject = find(userStore.profile?.files, {
                document_type: type,
              });
              return (
                <Tr key={`id-${i}`}>
                  <Td sx={style.td}>{type}</Td>
                  <Td sx={style.td}>
                    {fileObject === undefined && (
                      <AttachFile documentType={type} callback={uploaded} />
                    )}

                    {fileObject !== undefined && (
                      <Flex alignItems="center">
                        <Button
                          isLoading={isFileDownloading}
                          onClick={() => getFile(fileObject.name)}
                          variant="outline"
                          size="sm"
                          colorScheme="gray"
                          leftIcon={<IconDownload />}
                        >
                          {fileObject.name}
                        </Button>
                      </Flex>
                    )}
                  </Td>
                  <Td sx={style.td}>
                    {fileObject !== undefined
                      && moment
                        .unix(fileObject.created_at)
                        .format('DD.MM.YYYY H:mm:ss')}
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </div>
  );
}

export default observer(Index);
