import axios from 'axios';
import { isString } from 'lodash-es';
import userStore, { APP_TOKEN } from '@store/user';

const ApiClient = () => {
  const config = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {},
    paramsSerializer: (params: string | undefined) => new URLSearchParams(params),
  };

  const token = localStorage.getItem(APP_TOKEN);

  if (isString(token) && token !== '') {
    config.headers = { Authorization: `Bearer ${token}` };
  }

  axios.defaults.headers.post.headers = {
    'Content-Type': 'multipart/form-data',
  };

  // debug
  if (process.env.NODE_ENV === 'development' && process.env?.REACT_APP_DEBUG_MODE === '1') {
    axios.defaults.params = {};
    axios.defaults.params.XDEBUG_SESSION_START = 18185;
  }

  // @ts-ignore
  const a = axios.create(config);

  // middleware for catch 401
  a.interceptors.response.use((response) => response, (error) => {
    if (error?.response?.status === 401) {
      userStore.logout();
    }
    return Promise.reject(error);
  });

  return a;
};

const API = {
  test: {
    run: () => ApiClient().get('index/test'),
  },

  user: {
    login: (data: FormData) => ApiClient().post('user/login', data),
    me: () => ApiClient().get('user/me'),
    profile: () => ApiClient().get('user/profile'),
    profileChangeRequest: (data: FormData) => ApiClient().post('user/profile-change', data),
    signup: (data: FormData) => ApiClient().post('user/signup', data),
    confirm: (data: FormData) => ApiClient().post('user/confirm', data),
    getFile: (fileName: string) => ApiClient().get(`user/file/${fileName}`, { responseType: 'blob' }),
    remind: (data: FormData) => ApiClient().post('user/remind', data),
    passwordReset: (data: FormData) => ApiClient().post('user/password-reset', data),
    passwordNew: (data: FormData) => ApiClient().post('user/password-new', data),
    getDocumentTypes: () => ApiClient().get('user/document-types'),
    uploadDocument: (data: FormData) => ApiClient().post('user/documents', data),
    balanceHistory: () => ApiClient().get('user/balance'),
    balanceCheckout: (data: FormData) => ApiClient().post('user/balance', data),
  },

  notifications: {
    list: () => ApiClient().get('notifications/list'),
    readAll: () => ApiClient().get('notifications/read-all'),
    read: (id: number|string) => ApiClient().get(`notifications/read/${id}`),
    view: (id: number|string) => ApiClient().get(`notifications/view/${id}`),
    remove: (id: number|string) => ApiClient().delete(`notifications/remove/${id}`),
  },

  notificationsSettings: {
    get: () => ApiClient().get('notifications-settings/view'),
    update: (data: FormData) => ApiClient().post('notifications-settings/update', data),
  },

  purchase: {
    status: () => ApiClient().get('purchase/status'),
    list: (params: {}) => ApiClient().get('purchase/index', { params }),
    create: (data: FormData) => ApiClient().post('purchase/create', data),
    view: (id: number, params?: {}) => ApiClient().get(`purchase/view/${id}`, { params }),
    addFavourite: (id: number) => ApiClient().post(`purchase/favourite/${id}`),
    removeFavourite: (id: number) => ApiClient().delete(`purchase/favourite/${id}`),
    favourites: (params: {}) => ApiClient().get('purchase/favourite', { params }),
    getFile: (fileName: string) => ApiClient().get(`purchase/file/${fileName}`, { responseType: 'blob' }),
    cancel: (id: number) => ApiClient().post(`purchase/cancel/${id}`),
    update: (id: number, data: FormData) => ApiClient().post(`purchase/update/${id}`, data),
    setContractor: (id: number, data: FormData) => ApiClient().post(`purchase/set-contractor/${id}`, data),
  },

  order: {
    create: (data: FormData) => ApiClient().post('order/create', data),
    my: (params: {}) => ApiClient().get('order/my', { params }),
    view: (orderId: number|string, params?: {}) => ApiClient().get(`order/view/${orderId}`, { params }),
    trade: (orderId: number|string, data: FormData) => ApiClient().post(`order/trade/${orderId}`, data),
    update: (orderId: number|string, data: FormData) => ApiClient().post(`order/update/${orderId}`, data),
    status: () => ApiClient().get('order/status'),
    getFile: (fileName: string) => ApiClient().get(`order/file/${fileName}`, { responseType: 'blob' }),
  },

  contract: {
    my: (params: {}) => ApiClient().get('contract/my', { params }),
    view: (id: number|string, params: {}) => ApiClient().get(`contract/view/${id}`, { params }),
    getDocument: (name: string, id: string, user_type: string) => ApiClient().get(`contract/file?name=${name}&id=${id}&userType=${user_type}`),
    uploadDocument: (data: FormData) => ApiClient().post('contract/file-upload', data),
  }
};

export default API;
