import React from 'react';
import { Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import userStore from '@store/user';

function UsersTable({ orders }) {
  const bg = useColorModeValue('red.100', 'red.300');

  const highlightPositionRow = (userId) => {
    if (userStore.user.id === userId) {
      return bg;
    }
    return null;
  };

  return (
    <>
      <Text fontWeight="semibold" mb={5}>
        Участники
      </Text>
      <Table w="100%" mb={5} size="sm">
        <Thead>
          <Tr>
            <Th>ID участника</Th>
            <Th>Место</Th>
            <Th>Предложенная цена</Th>
            <Th>Время предложения</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orders
            && orders.map((order, index) => (
              <Tr
                key={order.id}
                bgColor={highlightPositionRow(order.user_id)}
              >
                <Td><Text fontWeight={userStore.user.id === order.user_id ? 'bold': 'normal'}>{order.user_id}</Text></Td>
                <Td><Text fontWeight={userStore.user.id === order.user_id ? 'bold': 'normal'}>{index + 1}</Text></Td>
                <Td>
                  <Text fontWeight={userStore.user.id === order.user_id ? 'bold': 'normal'}>
                  <NumberFormat
                    displayType="text"
                    value={order.price_finish}
                    thousandSeparator
                    suffix=" ₽"
                    fixedDecimalScale
                    decimalScale={2}
                  />
                  </Text>
                </Td>
                <Td>
                  <Text fontWeight={userStore.user.id === order.user_id ? 'bold': 'normal'}>
                  {moment
                    .unix(order.updated_at)
                    .locale('ru')
                    .format('llll')}
                  </Text>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </>
  );
}

export default UsersTable;
