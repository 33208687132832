import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import API from '@utils/api';
import InputMask from 'react-input-mask';
import userStore from '@store/user';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import moment from 'moment';

function Index({ isOpen, onClose }) {
  const validationSchema = yup.object().shape({
    legal_address: yup
      .string()
      .max(512, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    actual_address: yup
      .string()
      .max(512, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    head_position: yup
      .string()
      .max(64, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    head_name: yup
      .string()
      .max(64, 'Превышена максимальная длина поля')
      .required('Обязательное поле'),
    registration_date: yup.string().required('Обязательное поле'),
    contact_person: yup.string().max(64, 'Превышена максимальная длина поля'),
    additional_phone: yup.string().max(16, 'Превышена максимальная длина поля'),
  });

  const formik = useFormik({
    initialValues: {
      legal_address: userStore.profile?.legal_address,
      actual_address: userStore.profile?.actual_address,
      head_position: userStore.profile?.head_position,
      head_name: userStore.profile?.head_name,
      registration_date: userStore.profile?.registration_date && moment(userStore.profile?.registration_date).locale('ru').format('L'),
      contact_person: userStore.profile?.contact_person || '',
      additional_phone: userStore.profile?.additional_phone || '',
    },
    enableReinitialize: true,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { setErrors, resetForm }) => {
      const data = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        data.append(key, value);
      });

      await API.user
        .profileChangeRequest(data)
        .then((response) => {
          if (response.status === 200 && response.data === 'true') {
            toast.success('Запрос успешно отправлен!');
            resetForm();
            onClose();
          }
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            if (error.response?.data?.message) {
              const message = JSON.parse(error.response.data.message);
              setErrors(message);
            }
          } else {
            toast.error('Ошибка сервера. Повторите позже');
          }
        });
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <form onSubmit={formik.handleSubmit}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Редактирование профиля</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Alert status="error" mb={5}>
              <AlertIcon />
              <Box flex="1">
                <AlertTitle mb={2}>Внимание!</AlertTitle>
                <AlertDescription display="block" lineHeight={1.2} fontSize={14}>
                  После отправки заявки на изменение профиля, ваша аккредитация будет
                  приостановлена до момента проверки ваших данных модератором
                </AlertDescription>
              </Box>
            </Alert>

            <FormControl isRequired mb={5} isInvalid={formik.errors.legal_address}>
              <FormLabel>Юридический адрес</FormLabel>
              <Input type="text" {...formik.getFieldProps('legal_address')} />
              <FormErrorMessage>{formik.errors.legal_address}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired mb={5} isInvalid={formik.errors.actual_address}>
              <FormLabel>Фактический адрес</FormLabel>
              <Input type="text" {...formik.getFieldProps('actual_address')} />
              <FormErrorMessage>
                {formik.errors.actual_address}
              </FormErrorMessage>
            </FormControl>

            <FormControl isRequired mb={5} isInvalid={formik.errors.head_position}>
              <FormLabel>Должность руководителя</FormLabel>
              <Input type="text" {...formik.getFieldProps('head_position')} />
              <FormErrorMessage>{formik.errors.head_position}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired mb={5} isInvalid={formik.errors.head_name}>
              <FormLabel>ФИО руководителя</FormLabel>
              <Input type="text" {...formik.getFieldProps('head_name')} />
              <FormErrorMessage>{formik.errors.head_name}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired mb={5} isInvalid={formik.errors.registration_date}>
              <FormLabel>Дата регистрации организации</FormLabel>
              <Input
                type="text"
                as={InputMask}
                mask="99.99.9999"
                maskPlaceholder="дд.мм.гггг"
                {...formik.getFieldProps('registration_date')}
              />
              <FormErrorMessage>
                {formik.errors.registration_date}
              </FormErrorMessage>
            </FormControl>

            <FormControl mb={5} isInvalid={formik.errors.contact_person}>
              <FormLabel>Контактное лицо</FormLabel>
              <Input type="text" {...formik.getFieldProps('contact_person')} />
              <FormErrorMessage>
                {formik.errors.contact_person}
              </FormErrorMessage>
            </FormControl>

            <FormControl mb={5} isInvalid={formik.errors.additional_phone}>
              <FormLabel>Доп. телефон</FormLabel>
              <Input
                type="text"
                as={InputMask}
                mask="+7(999)999-99-99"
                {...formik.getFieldProps('additional_phone')}
              />
              <FormErrorMessage>
                {formik.errors.additional_phone}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" mr={3} isLoading={formik.isSubmitting}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Отмена
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}

export default observer(Index);
