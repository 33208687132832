import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  IconButton,
  Input,
  Skeleton,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import { IconArrowLeft2, IconPrint } from '@utils/icons';
import NumberFormat from 'react-number-format';
import Countdown from '@components/Trade/Countdown';
import { find, findIndex, isEmpty, maxBy } from 'lodash-es';
import Chart from '@components/Trade/Chart';
import API from '@utils/api';
import userStore from '@store/user';
import { toast } from 'react-toastify';
import { ErrorParser } from '@utils/common';
import appStore from '@store/app';
import { useHistory, useParams } from 'react-router-dom';
import UsersTable from '@components/Trade/UsersTable';
import purchaseStore from '@store/purchase';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';

function Trade() {
  const purchaseId = useParams().id;
  const history = useHistory();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [orders, setOrders] = useState([]);
  const [userOrder, setUserOrder] = useState({});
  const [newPrice, setNewPrice] = useState(0);
  const [position, setPosition] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [step, setStep] = useState(1);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [lastUpdatedOrder, setLastUpdatedOrder] = useState(0);
  const [notFoundError, setNotFoundError] = useState(false);
  const [isAutoInit, seIsAutoInit] = useState(0);
  const [backUrl, setBackUrl] = useState('/');
  const prevParam = (new URLSearchParams(window.location.search)).get('prev');

  const fetchPurchase = (loadingFunction, setAuto = null) => {
    loadingFunction(true);

    API.purchase
      .view(purchaseId, {
        expand: 'is_favourite,status_label,is_ordered,orders,current_price',
      })
      .then((response) => {
        setData(response.data);
        setOrders(response.data.orders);

        // Отображаем только активные или финальные торги
        if (response.data.status !== 3 && response.data.status !== 4) {
          history.push('/');
          toast.warning('Торги по данной заявке не ведутся');
          return;
        }

        if (response.data.trade_time_end === null) {
          // TODO: проверять по времени последнего шага ставки. Может быть баг из за переключателя "Авто торги"
          const u = maxBy(response.data.orders, 'updated_at');

          // Прибавляем 10 минут для задержки
          setLastUpdatedOrder(u?.updated_at + 10 * 60 || 0);
        } else {
          setLastUpdatedOrder(response.data.trade_time_end);
        }

        const order = find(response.data.orders, {
          user_id: userStore.user.id,
        });

        if (order) {
          setUserOrder(order);

          if (setAuto !== null && order) {
            setAuto(order.is_auto);
          }

          if (order.step) {
            setStep(Number(order.step));
          }
        }

        setNewPrice(Number(response.data.current_price - (response.data.current_price * (step / 100))).toFixed(2));

        const index = findIndex(response.data.orders, {
          user_id: userStore.user.id,
        });

        if (index > -1) {
          setPosition(index + 1);
        }

        setCurrentPrice(response.data.current_price);
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          setNotFoundError(true);
        } else if (error.response?.status === 403) {
          history.push('/');
          ErrorParser(error);
        } else {
          ErrorParser(error);
        }
      })
      .finally(() => loadingFunction(false));
  };

  const updatePrice = () => {
    if (!isEmpty(userOrder)) {
      setIsSubmitLoading(true);

      const data = new FormData();
      data.append('new_price', newPrice);

      API.order
        .trade(userOrder.id, data)
        .then((response) => {
          if (response.data === true) {
            toast.success('Заявка успешно отправлена');
          }
        })
        .catch((error) => ErrorParser(error))
        .finally(() => setIsSubmitLoading(false));
    }
  };

  const toggleAutoTrade = (value) => {
    const data = new FormData();
    data.append('is_auto', Number(value));

    API.order.update(userOrder.id, data).catch((error) => ErrorParser(error));
  };

  const percentFromValue = (from, value) => parseFloat(((from - value) * 100) / from).toFixed(2);

  useEffect(() => {
    fetchPurchase(setIsPageLoading, seIsAutoInit);

    appStore.centrifugo.subscribe(`trade$${purchaseId}`, (message) => {
      if (message?.data) {
        fetchPurchase(setIsLoading);
      }
    });

    if (isEmpty(purchaseStore.statusList)) {
      purchaseStore.fetchStatusList();
    }
  }, []);

  useEffect(() => {
    if (prevParam) {
      setBackUrl(`/?prev=${prevParam}`);
    }
  }, [prevParam]);

  useEffect(() => {
    if (step > 0 && newPrice > 0) {
      setNewPrice(Number(currentPrice - (currentPrice * (step / 100))).toFixed(2));
    }
  }, [step]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Box py={10} px={5} ref={componentRef}>
      <Box maxW="1024px" m="auto">
        <Flex mb={5}>
          <IconButton
            aria-label="close"
            mr={10}
            icon={<IconArrowLeft2 />}
            size="lg"
            variant="outline"
            onClick={() => history.push(backUrl)}
          />

          <Flex alignItems="center" mb={5}>
            {isPageLoading === false && (
              <Heading>
                Торги
                {notFoundError === false ? ` № ${purchaseId}` : ' не найдены'}
                {data.status === 4 && ' завершены'}
              </Heading>
            )}

            {isPageLoading === false && data.status === 4 && (
              <IconButton
                icon={<IconPrint />}
                onClick={handlePrint}
                aria-label="print"
                ml={5}
                variant="outline"
              />
            )}
          </Flex>
        </Flex>

        {isPageLoading === true && (
          <Flex justifyContent="center" my={5}>
            <Flex alignItems="center">
              <Text mr={5}>Загрузка...</Text>
              <CircularProgress isIndeterminate size="30px" color="brand.red" />
            </Flex>
          </Flex>
        )}

        {isEmpty(userOrder) && isPageLoading === false && (<Text>Вы не участвовали в торгах</Text>)}

        {notFoundError === false && isPageLoading === false && !isEmpty(userOrder) && (
          <>
            <Grid templateColumns="repeat(2, 1fr)" gap={5}>
              <Box>
                <VStack alignItems="flex-start" mt={3}>
                  <Flex>
                    <CircularProgress
                      max={userOrder.price_start}
                      value={
                        userOrder.price_start
                        - (userOrder.price_start - userOrder.price_finish)
                      }
                      mr={5}
                      size="80px"
                      color="brand.red"
                    />
                    <Stat>
                      <StatLabel>Ваше последнее предложение:</StatLabel>
                      <StatNumber>
                        <Skeleton isLoaded={!isLoading}>
                          <NumberFormat
                            displayType="text"
                            value={userOrder.price_finish}
                            thousandSeparator
                            suffix=" ₽"
                            fixedDecimalScale
                            decimalScale={2}
                          />
                        </Skeleton>
                      </StatNumber>
                      <StatHelpText>
                        <StatArrow type="decrease" />
                        {percentFromValue(userOrder.price_start, userOrder.price_finish)}
                        %
                      </StatHelpText>
                    </Stat>
                  </Flex>

                  <HStack>
                    <Text>Текущая цена:</Text>
                    <Skeleton isLoaded={!isLoading}>
                      <Text fontWeight="bold">
                        <NumberFormat
                          displayType="text"
                          value={currentPrice}
                          thousandSeparator
                          suffix=" ₽"
                          fixedDecimalScale
                          decimalScale={2}
                        />
                      </Text>
                    </Skeleton>
                  </HStack>

                  <HStack>
                    <Text>Начальная цена:</Text>
                    <Skeleton isLoaded={!isLoading}>
                      <Text fontWeight="semibold">
                        <NumberFormat
                          displayType="text"
                          value={data.approximate_price}
                          thousandSeparator
                          suffix=" ₽"
                          fixedDecimalScale
                          decimalScale={2}
                        />
                      </Text>
                    </Skeleton>
                  </HStack>

                  <HStack>
                    <Text>Ваша позиция:</Text>
                    <Skeleton isLoaded={!isLoading}>
                      <Text fontWeight="semibold">{position}</Text>
                    </Skeleton>
                  </HStack>
                  <HStack>
                    <Text>Время окончания:</Text>
                    <Text fontWeight="semibold">{moment.unix(data.trade_time_end).format('lll')}</Text>
                  </HStack>
                  <HStack>
                    <Text>Время до окончания:</Text>
                    <Countdown eventTime={lastUpdatedOrder} />
                  </HStack>
                </VStack>
              </Box>
              <Box>
                <Heading size="md" mb={2}>
                  Информация о закупке
                </Heading>
                <Text>{data.description}</Text>

                {data.status === 3 && (
                  <>
                    <FormControl mt={5}>
                      <FormLabel fontWeight="semibold">
                        Шаг скидки (%)
                      </FormLabel>
                      <Slider
                        focusThumbOnChange={false}
                        value={step}
                        onChange={(value) => setStep(value)}
                        min={0.5}
                        max={5}
                        step={0.5}
                      >
                        <SliderTrack>
                          <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb
                          fontSize={12}
                          boxSize={8}
                          children={step}
                          color="brand.red"
                        />
                      </Slider>
                    </FormControl>

                    <FormControl mt={3}>
                      <FormLabel fontWeight="semibold">
                        Ваше предложение
                      </FormLabel>
                      <Flex>
                        <Input
                          type="number"
                          max={userOrder.price_finish}
                          min={1}
                          defaultValue={newPrice}
                          value={newPrice}
                          onChange={(e) => setNewPrice(e.target.value)}
                          mr={3}
                        />
                        <Button
                          onClick={updatePrice}
                          isLoading={isSubmitLoading}
                        >
                          Отправить
                        </Button>
                      </Flex>
                    </FormControl>

                    {isAutoInit === 1 && userOrder.is_auto === 1 && (
                      <FormControl display="flex" alignItems="center" mt={5}>
                        <FormLabel htmlFor="robot" mb={1} fontWeight="semibold">
                          Активный робот
                        </FormLabel>
                        <Switch
                          id="robot"
                          defaultIsChecked
                          onChange={(e) => toggleAutoTrade(e.target.checked)}
                        />
                      </FormControl>
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Divider my={10} />

            {!isEmpty(orders) && (
              <>
                <UsersTable orders={orders} />
                <Divider my={10} />
              </>
            )}

            {!isEmpty(orders) && <Chart orders={orders} />}
          </>
        )}
      </Box>
    </Box>
  );
}

export default Trade;
