import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import userStore, { APP_TOKEN } from '@store/user';
import { isEmpty } from 'lodash-es';
import Loader from '@components/Loader';
import jwtDecode from 'jwt-decode';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';

function ProtectedRoute({ ...restOfProps }) {
  const isAuthenticated = localStorage.getItem(APP_TOKEN);

  if (isAuthenticated === null) {
    return <Redirect to="/login" />;
  }

  useEffect(() => {
    if (userStore.token === null || isEmpty(toJS(userStore.user))) {
      userStore.getUser();
    }

    if (userStore.token !== null) {
      const decodedToken = jwtDecode(userStore.token);
      const currentDate = new Date();
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        userStore.getUser();
      }
    }
  }, [userStore.token]);

  return userStore.isLoading || isEmpty(toJS(userStore.user)) ? (
    <Loader />
  ) : (
    <Route
      {...restOfProps}
    />
  );
}

export default observer(ProtectedRoute);
