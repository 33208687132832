import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
} from '@chakra-ui/react';
import API from '@utils/api';
import ReactHtmlParser from 'react-html-parser';
import { toast } from 'react-toastify';

function NotificationModal({ isOpen, onClose, messageID, fetchNotifications }) {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);

  const removeNotification = () => {
    setIsLoadingRemove(true);
    API.notifications
      .remove(messageID)
      .then(() => {
        fetchNotifications();
        onClose();
      })
      .catch((error) => {
        toast.error(error?.response?.data || 'Ошибка удаления уведомления');
      })
      .finally(() => setIsLoadingRemove(false));
  };

  useEffect(() => {
    if (messageID) {
      setIsLoading(true);
      API.notifications
        .view(messageID)
        .then((response) => {
          if (response?.data?.data) {
            setMessage(JSON.parse(response.data.data).message);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [messageID]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Просмотр сообщения</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading && <Progress size="xs" isIndeterminate />}
          {ReactHtmlParser(message)}
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            colorScheme="red"
            mr={3}
            isLoading={isLoadingRemove}
            onClick={removeNotification}
          >
            Удалить
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Закрыть
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default NotificationModal;
