import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Flex,
  Heading,
  Text, useColorModeValue,
} from '@chakra-ui/react';
import queryString from 'query-string';
import API from '@utils/api';
import { useLocation } from 'react-router-dom';
import { has } from 'lodash-es';
import userStore from '@store/user';

function Confirm() {
  const location = useLocation();
  const params = queryString.parse(location.search);

  // eslint-disable-next-line camelcase
  const { id, auth_key } = params;

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [accessToken, setAccessToken] = useState(null);

  const login = () => {
    userStore.setToken(accessToken);
    window.location.href = '/login';
  };

  useEffect(() => {
    // eslint-disable-next-line camelcase
    if (id === undefined || auth_key === undefined) {
      window.location.href = '/login';
    }

    const data = new FormData();

    data.append('id', id);
    data.append('auth_key', auth_key);

    API.user.confirm(data)
      .then((response) => {
        if (response.status !== 200) {
          setError('Ошибка сервера');
        } else if (response.status === 200 && has(response.data, 'access_token')) {
          setAccessToken(response.data.access_token);
        }
      })
      .catch((er) => {
        if (er.response?.status === 422) {
          setError(er.response.data.message);
        } else {
          setError('Ошибка сервера');
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      minH="100vh"
      bg={useColorModeValue('gray.200', 'gray.600')}
      justifyContent="center"
      alignItems="center"
    >
      <Helmet>
        <title>Подтверждение регистрации – Stom Tender</title>
      </Helmet>

      <Heading color={useColorModeValue('brand.blueBlack', 'gray.400')} mt={5} mb={5}>
        Подтверждение регистрации
      </Heading>

      {isLoading && (
      <Flex
        bg={useColorModeValue('whiteAlpha.900', 'gray.800')}
        boxShadow="md"
        alignItems="center"
        p={5}
        px={10}
      >
        <CircularProgress isIndeterminate color="red" />
        <Text ml={5}>Проверяем...</Text>
      </Flex>
      )}

      {isLoading === false && error !== ''
        && (
        <Box>
          <Alert
            status="error"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">Ошибка!</AlertTitle>
            <AlertDescription>
              Не удалось подтвердить учетную запись. Неверный ключ или ID пользователя.
            </AlertDescription>
          </Alert>
        </Box>
        )}

      {isLoading === false && accessToken !== null
        && (
          <>
            <Box>
              <Alert
                status="success"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
              >
                <AlertIcon boxSize="40px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">Успешно!</AlertTitle>
                <AlertDescription>Учетная запись подтверждена.</AlertDescription>
              </Alert>
            </Box>

            <Button mt={5} onClick={login}>Перейти в личный кабинет</Button>
          </>
        )}
    </Flex>
  );
}

export default Confirm;
