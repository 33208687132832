import { makeAutoObservable } from 'mobx';
import API from '@utils/api';
import { toast } from 'react-toastify';

class PurchaseStore {
  pageSize = 0;

  pageIndex = 0;

  pageCount = 0;

  sortBy = {};

  favouritePageSize = 0;

  favouritePageIndex = 0;

  favouritePageCount = 0;

  favouriteSortBy = {};

  statusList = {};

  statusListIsLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setPageSize(value) {
    this.pageSize = value;
  }

  setPageIndex(value) {
    this.pageIndex = value;
  }

  setPageCount(value) {
    this.pageCount = value;
  }

  setSortBy(value) {
    this.sortBy = value;
  }

  setFavouritePageSize(value) {
    this.favouritePageSize = value;
  }

  setFavouritePageIndex(value) {
    this.favouritePageIndex = value;
  }

  setFavouritePageCount(value) {
    this.favouritePageCount = value;
  }

  setFavouriteSortBy(value) {
    this.favouriteSortBy = value;
  }

  setStatusListIsLoading(value) {
    this.statusListIsLoading = value;
  }

  setStatusList(value) {
    this.statusList = value;
  }

  fetchStatusList() {
    if (this.statusListIsLoading === false) {
      this.setStatusListIsLoading(true);

      API.purchase
        .status()
        .then((response) => {
          this.setStatusList(response.data);
        })
        .catch(() => {
          toast.error('Не удалось получить список статусов закупок');
        })
        .finally(() => this.setStatusListIsLoading(false));
    }
  }
}

export default new PurchaseStore();
